import React, {useContext, useEffect, useState} from 'react';
import {converter_RUB_S, converter_S_RUB, CURSE, priceToUserString} from "../../http/SupportFunctions";
import {Link, Navigate, useParams} from "react-router-dom";
import {Context} from "../../index";
import {createAromaAndPost, createEntranceForAroma, getAromaAndEntrance, getLastEntrance} from "../../http/AromaApi";
import {useLocation, useNavigate} from "react-router";
import {fetchAllBrand} from "../../http/BrandApi";
import {fetchAllPost} from "../../http/PostavAPI";
import "./addNewEntrance.css";
import {set} from "mobx";
import {almostEquals} from "chart.js/helpers";
const AddNewEntrance = () => {
    const {settings,user} = useContext(Context);


    const [postSelect, setPostSelect] = useState(null);
    const [sostavshikArray, setPostavshikArray] = useState(null);
    const [postavSearchQuery, setPostavSearchQuery] = useState('')
    const [openPostavSelect, setOpenPostavSelect] = useState(false);
    const [messageBackend, setMessageBackend] = useState('');
    const [typeMessage, setTypeMessage] = useState(false);

    //##

    const [volumeMl, setVolumeMl] = useState(null);
    const [count, setCount] = useState(1);
    const [price_S, setPrice_S] = useState(null);
    const [price_R, setPrice_R] = useState(null);
    const [priceMl_S, setPriceMl_S] = useState(null);
    const [priceMl_R, setPriceMl_R] = useState(null)
    const [comment, setComment] = useState("");

    const [deactiveButton, setDeactiveButton] = useState(false);

    //##

    // brand, name, ml, volume, count, price_S, price_R, postavshik, price_S_ml, price_R_ml, comment




    const [searchRoleParam, setSearchRoleParam] = useState("");
    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')
    const [refresh,setRefresh] = useState(false);
    const Navigate = useNavigate();


    const id = params.id;


    const [ruTriger,setRuTriger] = useState(false);
    const [allTriger,setAllTriger] = useState(false);
    const [sTruger,setSTriger] = useState(false)
    const [procentPriceChange,setProcentPriceChange] = useState(null)
    const [lastEntrance,setLastEntrance] = useState(  {
        ml_price_R:"0",
        ml_price_S:"0",
        nameUser:"Administrator",
        ost_price:"0",
        postavshikId:1,
        price_R:"0",
        price_S:"0",
    });

    const [childProducts,setChildProducts] = useState(null);


    useEffect(()=> {
        getLastEntrance(id).then(response => {
            setLastEntrance(response.findLastEntrance);
            console.log("LAST ENTRANSE")
            console.log(response)
        })
        getAromaAndEntrance(id).then(response => {
            if(response.error) {
                return false;
            }

            let ml = response.items_ml;

            ml.sort(function(a, b) {
                return parseFloat(a.value) - parseFloat(b.value);
            });

            setChildProducts(ml);


        })
    },[])

    useEffect( () => {
        console.log(price_S)
        console.log(lastEntrance)
        if(price_S) {
            let priceR = converter_S_RUB(price_S, settings.getRubCurse)
            let priceRml = converter_S_RUB(priceMl_S, settings.getRubCurse)
            let mlPrice_s = price_S/volumeMl;
            let mlPrice_r = converter_S_RUB(mlPrice_s,settings.getRubCurse);

            setPrice_R(priceR)
            setPriceMl_R(priceRml)
            // в мл
            setPriceMl_S(mlPrice_s.toFixed(2));
            setPriceMl_R(mlPrice_r);
            // mlPrice_r = mlPrice_r.toFixed(0);

            let procent = ((mlPrice_s - lastEntrance.ml_price_S)/ lastEntrance.ml_price_S * 100).toFixed(2);
            console.log('PROCENT');
            console.log(procent)
            if(procent > 15000) {
                procent = 0;
            }
            setProcentPriceChange(procent)
            // за флакон

        }

    },[sTruger,allTriger])

    useEffect( () => {

        if(price_R) {

            let priceS = converter_RUB_S(price_R, settings.getRubCurse)
            console.log("PRICE SSSSS")
            console.log(priceS)
            console.log(price_R)
            console.log(lastEntrance.ml_price_S + "LAST ENTRICE")


            console.log("======================================")
            let priceSml = (priceS/volumeMl).toFixed(2);
            let mlPrice_RU = (price_R/volumeMl).toFixed(2);
            setPriceMl_R(mlPrice_RU);
            // let mlPrice_r = converter_RUB_S(mlPrice_st,CURSE);
            //
            // за флакон
            // let mlPrice_s = price_S/volumeMl;
            setPrice_S(priceS)
            setPriceMl_S(priceSml)

            let procent = ((priceSml - lastEntrance.ml_price_S)/ lastEntrance.ml_price_S * 100).toFixed(2);

            if(procent > 15000) {
                procent = 0;
            }
            setProcentPriceChange(procent)

            // в мл
            // setPriceMl_S(mlPrice_st);

        }

    },[ruTriger,allTriger])

    const selectPostHandler = (item) => {
        setPostSelect(item)
        setOpenPostavSelect(false);
    }

    useEffect(() => {
        fetchAllPost(999,0,true,searchRoleParam,postavSearchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setPostavshikArray(response.rows);
        })
    },[page,postavSearchQuery])



    const createEntranceForAromaHandler = (volumeMl,count,price_S,price_R,postSelect, priceMl_S, priceMl_R, comment, user, id) => {
        setDeactiveButton(true);
        settings.setPreloader(true);
        if(deactiveButton) {
            return false;
        }
        createEntranceForAroma(volumeMl,count,price_S,price_R,postSelect, priceMl_S, priceMl_R, comment, user, id).then(response => {
            if(response.error) {
                setDeactiveButton(false);
                setMessageBackend(response.error)
                setTypeMessage(false);
                settings.setPreloader(false);
            }
            if(response.message) {
                setDeactiveButton(false);
                setMessageBackend(response.message);
                setTypeMessage(true);
                settings.setPreloader(false);
                Navigate(`/adm/aroma/${id}`)
            }
        })
    }


    const changeVolumeHandler = (value) => {

        let modifyValue = value.replace(/\D/g, '')
        setVolumeMl(modifyValue)
        setAllTriger(!allTriger)
    }



    return (
        <div className={"page_add_new_Entrance"}>


        <div className={"wrapper_for_entrance_add"}>
            <div className={"add_new_btn"} onClick={() => Navigate(-1)}>Вернуться назад</div>
            <span className={"entrance_title_block"}>Добавить поступление</span>
            <div className={"entrance_container_container"}>
                <div className="entrance_field_container">
                    <div className={"name_field_entrance_container"}>Поставщик</div>
                    <div   className={ openPostavSelect ? "entrance_selector_container active" : "entrance_selector_container" }>
                        <span onClick={e => setOpenPostavSelect(!openPostavSelect)}> { postSelect ? postSelect.name : "Выберите поставщика"  }</span>
                        <div className={"menu_hidden_area"}>

                            <div className={"aroma_wrapp_for_search"}>
                                <div className={'seach_container'}>
                                    <input
                                        className={"search_input"}
                                        value={postavSearchQuery ? postavSearchQuery : ""}
                                        onChange={ e => setPostavSearchQuery(e.target.value)}
                                        placeholder={"Поиск..."} type="text"/>
                                    <div className={"abs_lupa"}>
                                        <img src="/lupa.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className={"scroll_container_aroma"}>
                                {
                                    sostavshikArray ? sostavshikArray.map((item, index) =>
                                        <div onClick={e => selectPostHandler(item)} className={"wrapper_for_select_brand"} key={index}>
                                            <span  className={"one_brand_item"}>{item.name}</span>
                                        </div>
                                    ) : ''
                                }
                            </div>
                            <Link className={"redirect_button"} to="/adm/post" target="_blank" rel="noopener noreferrer">Добавить поставщика</Link>
                        </div>
                    </div>
                </div>
                <div className="entrance_field_container">
                    <div className={"name_field_entrance_container purple"}>Цена $</div>
                    <input onChange={e => [setPrice_S(e.target.value),setSTriger(!sTruger)]} value={price_S ? price_S : ""} className={"two_entrance_input"} type="text"/>
                    <div className={"name_field_entrance_container orange"}>Цена ₽</div>
                    <input onChange={e => [setPrice_R(e.target.value), setRuTriger(!ruTriger)]} value={price_R ? price_R : ""} className={"two_entrance_input"} type="text"/>
                </div>
                <div className={"entrance_field_container"}>
                    <div className="entrance_field_container">
                        <div className={"name_field_entrance_container"}>Объем, мл</div>
                        <input onChange={e => changeVolumeHandler(e.target.value)} value={ volumeMl ? volumeMl : "" } className={"two_entrance_input"} type="text"/>
                    </div>
                    <div className="entrance_field_container">
                        <div className={"name_field_entrance_container"}>Кол-во</div>
                        <input onChange={e => setCount(e.target.value)} value={ count ? count : "" } className={"two_entrance_input"} type="text"/>
                    </div>
                </div>






                <div className="entrance_field_container">
                    <div className={"name_field_entrance_container"}>Цена за мл, $</div>
                    <input  value={priceMl_S ? priceMl_S : ""} className={"two_entrance_input"} type="text"/>
                    <div className={"name_field_entrance_container"}>Цена за мл, ₽</div>
                    <input  value={priceMl_R ? priceMl_R : ""} className={"two_entrance_input"} type="text"/>
                </div>


                <div className={ procentPriceChange > 0 ? "entrance_field_container price_change red" : "entrance_field_container price_change blue"}>
                    <div className={"name_field_entrance_container"}>Изменение цены</div>
                    <input value={procentPriceChange ? procentPriceChange+" %" : ""}   className={"big_entrance_input_container"} type="text"/>
                </div>
                <div className="entrance_field_container">
                    <div className={"name_field_entrance_container"}>Примечание</div>
                    <input onChange={e => setComment(e.target.value)} value={comment ? comment : ""}   className={"big_entrance_input_container"} type="text"/>
                </div>
            </div>
        </div>
            <div className={"save_aroma_line"}>
                <span className={typeMessage ? "green message" : "red message"}>{messageBackend}</span>
                <span onClick={e => createEntranceForAromaHandler(volumeMl,count,price_S,price_R,postSelect, priceMl_S, priceMl_R, comment, user, id)} className={deactiveButton ? "add_new_entrance deactive_button" : "add_new_entrance" }>Сохранить</span>
            </div>
            <div className={"wrapper_silout_items"}>
                <span className={"wls_head"}>Доступные объемы</span>
                <div className={"wrapper_for_flex"}>
                    {
                        childProducts ? childProducts.map((item, index) =>
                            <div onClick={e => selectPostHandler(item)} className={"item_keys"} key={index}>
                                <span  className={"one_item_oust"}>{item.value}</span>
                            </div>
                        ) : ''
                    }
                </div>
            </div>

        </div>
    );
};

export default AddNewEntrance;

