import React from 'react';
import "./dashcontrollpage.css";
import {Link} from "react-router-dom";
const DashboardControllerPage = (props) => {
    return (

        <div className={"wrapper_controller"}>
            <Link className={props.page === "ost" ? "item_controll_spt active" : "item_controll_spt"} to={"/adm/dashboard/ost/"}>Остатки товара по мл</Link>
            <Link className={props.page === "temp" ? "item_controll_spt active" : "item_controll_spt"} to={"/adm/dashboard/temp/"}>Темп расхода товара</Link>
            <Link className={props.page === "info" ? "item_controll_spt active" : "item_controll_spt"} to={"/adm/dashboard/info/"}>Общая информация по товарам</Link>
            <Link className={props.page === "newaromadiagramm" ? "item_controll_spt active" : "item_controll_spt"} to={"/adm/dashboard/newaromadiagramm/"}>График новых ароматов</Link>
            <Link className={props.page === "entrancediagramm" ? "item_controll_spt active" : "item_controll_spt"} to={"/adm/dashboard/entrancediagramm/"}>График поставок ароматов</Link>
            <Link className={props.page === "diversity" ? "item_controll_spt active" : "item_controll_spt"} to={"/adm/dashboard/diversity/"}>Проданные ароматы по месяцам</Link>
            <Link className={props.page === "forecast" ? "item_controll_spt active" : "item_controll_spt"} to={"/adm/dashboard/forecast/"}>Прогноз</Link>
            <Link className={props.page === "nosell" ? "item_controll_spt active" : "item_controll_spt"} to={"/adm/dashboard/nosell/"}>Не продается</Link>
        </div>
    );
};

export default DashboardControllerPage;