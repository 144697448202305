import React, {useContext, useEffect, useState} from 'react';
import "./AllAromaInfroDash.css"
import {observer} from "mobx-react-lite";
import {fetchAllSet} from "../../http/setApi";
import {useLocation} from "react-router";
import {Link, useParams} from "react-router-dom";
import {fetchRashodByTime, GetAllAromaInformation, getOstatkiByFilter} from "../../http/AromaApi";
import Pagination from "../../components/UI/Paginstion";
import DashboardControllerPage from "../../components/DashboardControllerPage/DashboardControllerPage";
import {set} from "mobx";
import Chart from "../../components/UI/Chart/Chart";
import {Context} from "../../index";


const AllAromaInfoDash = observer(() => {


    const [infoState,setInfoState] = useState(null)
    const {settings,user} = useContext(Context);

    useEffect(() => {
        GetAllAromaInformation().then(response => {
            console.log(response)
            setInfoState(response)
        })
    },[])

    return (
        <div className={"all_ml_ost_page"}>
            <span className={"name_page_default"}>Общая информация по товарам</span>
            <DashboardControllerPage page={"info"} />
            {
                user.getUserRights && user.getUserRights.all_info_item &&
                    <div>
                        {
                            infoState ?
                                <div className={"info_aroma_wrapper"}>
                                    <div className={"inf_line"}>
                                        <div className="one_box_bord strong">Общее количество ароматов в каталоге</div>
                                        <div className="one_box_bord strong">Общее количество ароматов в наличии</div>
                                        <div className="one_box_bord strong">Общий остаток на складе, мл</div>
                                        <div className="one_box_bord strong">Закупочная цена всего остатка, ₽</div>
                                    </div>
                                    <div className="inf_line">
                                        <div className="one_box_bord">{infoState && infoState.allAroma ? infoState.allAroma : 0 }</div>
                                        <div className="one_box_bord">{infoState && infoState.nalAromats ? infoState.nalAromats : 0 }</div>
                                        <div className="one_box_bord">{infoState && infoState.mlCount ? infoState.mlCount : 0 }</div>
                                        <div className="one_box_bord">{infoState && infoState.price ? infoState.price.toFixed(2)+" ₽" : 0 }</div>
                                    </div>
                                </div>
                                :
                                <div className={"info_aroma_wrapper"}>
                                    <span className={"wait_for_loading"}>Загрузка данных, подождите...</span>
                                </div>
                        }
                    </div>
            }



            {
                user.getUserRights && !user.getUserRights.all_info_item &&

                <div className={"close_page_for_you"}>
                    <span>Доступ закрыт</span>
                </div>
            }



        </div>
    );
});

export default AllAromaInfoDash;




