import React, {useContext, useEffect, useState} from 'react';
import "./userRights.css";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {getUser, updateUserRights} from "../../http/userAPI";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";
import {useLocation} from "react-router";
import {useParams} from "react-router-dom";
const UserRights = observer(() => {


    const {user,settings} = useContext(Context)

    const [currentUser,setCurrentUser] = useState(null);
    const [userRightsArray, setUserRightsArray] = useState({
            guide_cash_view: false,
            guide_cash_edit: false,
            guide_global_nac_view:false,
            guide_global_nac_edit:false,
            guide_nacenki_za_potery_view:false,
            guide_nacenki_za_potery_edit:false,
            guide_all_rashod_view:false,
            guide_all_rashod_edit:false,
            guide_nacenki_po_ostatky_view:false,
            guide_nacenki_po_ostatky_edit:false,
            guide_nacenki_na_nabor_view:false,
            guide_nacenki_na_nabor_edit:false,
            guide_nacenka_opt_view:false,
            guide_nacenka_opt_edit:false,
            guide_nacenka_for_site_price_view:false,
            guide_nacenka_for_site_price_edit:false,
            guide_brand_view:false,
            guide_brand_edit:false,
            guide_post_view:false,
            guide_post_edit:false,
            guide_itog_rashod_ot_flakona_view:false,
            guide_itgo_rashod_ot_flakona_edit:false,
            items_aroma_view:false,
            add_aroma:false,
            edit_aroma_name:false,
            set_articul_aroma:false,
            view_all_price:false,
            create_sets:false,
            view_sets:false,
            edit_sets:false,
            view_price_sets:false,
            view_history_buys:false,
            view_history_post:false,
            edit_item_ostatok:false,
            dash_ostatki_tovara:false,
            temp_rashoda_aromata:false,
            all_info_item:false,
            dash_new_aroma:false,
            dash_raznoobr_aroma:false,
        }
    )

    const location = useLocation();
    const params = useParams()
    let id = params.id;
    console.log(id)
    console.log("ID")

    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');


    const supportChangeValue = (nameField) => {
        let newArray =  {...userRightsArray};
        newArray[nameField] = !newArray[nameField];
        setUserRightsArray(newArray)
    }

    const saveUserRights = async () => {
       let result = await updateUserRights(id, JSON.stringify(userRightsArray))
        if(result.message) {
            user.setShadowOverlay(true)
            setOpenSuccessModal(true);
            setSuccessText("Права успешно обновлены")
        }
    }

    const [currentUserPage,setCurrentUserPage] = useState({});

    useEffect(() => {
        getUser(id).then(response => {
            if(response) {
                if(response.user) {
                    setCurrentUserPage(response.user);
                    if(response.user.rights) {
                        if(response.user.rights.length) {
                            setUserRightsArray(JSON.parse(response.user.rights))
                        }
                    }
                }
            }
            })
    },[])



    useEffect(() => {
        if(user.shadowOverlay == false) {
            // setOpenAddPostModal(false);

            setOpenSuccessModal(false);
        }
    },[user.shadowOverlay])



        if(user && user.user.role == "admin") {
            return (
                <div className={"user_rights_page"}>
                    <SuccessModal isOpen={openSuccessModal} text={successText} />
                    <div className="first_user_right_line">
                        <div className={"box_user_right"}>
                            <span className={"rhml_ssn"}>Пользователь:</span>
                            <span className={"rhml_name"}>{currentUserPage.name}</span>
                        </div>
                        <div className={"box_user_right"}>
                            <span className={"rhml_ssn"}>Логин:</span>
                            <span className={"rhml_name"}>{currentUserPage.login}</span>
                        </div>
                    </div>
                    <div className={"wrapper_control_user_rights"}>
                        <div className={"one_container_user_rights"}>
                            <span className={"area_title_rhts"}>Справочник</span>

                            <div className={"wrapper_for_lines_rights"}>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Курс валют</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_cash_view")} className={ userRightsArray.guide_cash_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_cash_edit")} className={userRightsArray.guide_cash_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Глобальный коэффициент наценки</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_global_nac_view")} className={userRightsArray.guide_global_nac_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_global_nac_edit")}  className={userRightsArray.guide_global_nac_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Наценка за потери</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenki_za_potery_view")} className={userRightsArray.guide_nacenki_za_potery_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenki_za_potery_edit")} className={userRightsArray.guide_nacenki_za_potery_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Общие расходы</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_all_rashod_view")} className={userRightsArray.guide_all_rashod_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_all_rashod_edit")} className={userRightsArray.guide_all_rashod_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Наценка по остатку</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenki_po_ostatky_view")} className={userRightsArray.guide_nacenki_po_ostatky_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenki_po_ostatky_edit")} className={userRightsArray.guide_nacenki_po_ostatky_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Наценка на набор</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenki_na_nabor_view")}  className={userRightsArray.guide_nacenki_na_nabor_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenki_na_nabor_edit")}  className={userRightsArray.guide_nacenki_na_nabor_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Наценка оптовая</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenka_opt_view")}  className={userRightsArray.guide_nacenka_opt_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenka_opt_edit")}  className={userRightsArray.guide_nacenka_opt_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Наценка для цены на сайте</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenka_for_site_price_view")} className={userRightsArray.guide_nacenka_for_site_price_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_nacenka_for_site_price_edit")} className={userRightsArray.guide_nacenka_for_site_price_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Справочник брендов</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_brand_view")} className={userRightsArray.guide_brand_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_brand_edit")} className={userRightsArray.guide_brand_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Справочник поставщиков</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_post_view")} className={userRightsArray.guide_post_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_post_edit")} className={userRightsArray.guide_post_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"user_rights_lines"}>
                                    <div className={"one_user_line_rights"}>
                                        <span>Итоговый расход зависящий от объёма флакона</span>
                                        <div className={"box_container_for_check"}>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_itog_rashod_ot_flakona_view")} className={userRightsArray.guide_itog_rashod_ot_flakona_view ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Просмотр</span>
                                            </div>
                                            <div className={"one_checkbox_wrap"}>
                                                <div onClick={e => supportChangeValue("guide_itgo_rashod_ot_flakona_edit")} className={userRightsArray.guide_itgo_rashod_ot_flakona_edit ? "currently_checkbox true" : "currently_checkbox" }></div>
                                                <span className={"rhs_tm"}>Изменение</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className={"aromats_area"}>
                                <span className={"span_title_area"}>Товары</span>
                                <div className={"arm_area_lines"}>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Просмотр раздела ароматы</span>
                                        <div onClick={e => supportChangeValue("items_aroma_view")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.items_aroma_view ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Добавление товаров</span>
                                        <div onClick={e => supportChangeValue("add_aroma")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.add_aroma ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Редактирование названия аромата</span>
                                        <div onClick={e => supportChangeValue("edit_aroma_name")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.edit_aroma_name ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"set_articul_aroma"}>Установка артикулов аромата</span>
                                        <div  onClick={e => supportChangeValue("set_articul_aroma")} className={"checkbox_polz"}>
                                            <span className={ userRightsArray.set_articul_aroma ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Просмотр всех цен</span>
                                        <div onClick={e => supportChangeValue("view_all_price")} className={"checkbox_polz"}>
                                            <span className={ userRightsArray.view_all_price ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Создание наборов</span>
                                        <div onClick={e => supportChangeValue("create_sets")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.create_sets ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Просмотр наборов</span>
                                        <div  onClick={e => supportChangeValue("view_sets")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.view_sets ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Редактирование наборов</span>
                                        <div onClick={e => supportChangeValue("edit_sets")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.edit_sets ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Просмотр цен на наборы</span>
                                        <div onClick={e => supportChangeValue("view_price_sets")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.view_price_sets ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Просмотр истории списания</span>
                                        <div onClick={e => supportChangeValue("view_history_buys")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.view_history_buys ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Просмотр истории поступлений</span>
                                        <div onClick={e => supportChangeValue("view_history_post")} className={"checkbox_polz"}>
                                            <span className={ userRightsArray.view_history_post ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Корректировка остатков ароматов</span>
                                        <div onClick={e => supportChangeValue("edit_item_ostatok")} className={"checkbox_polz"}>
                                            <span className={ userRightsArray.edit_item_ostatok ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className={"aromats_area"}>
                                <span className={"span_title_area"}>Дашборд</span>
                                <div className={"arm_area_lines"}>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Остатки товара по мл</span>
                                        <div onClick={e => supportChangeValue("dash_ostatki_tovara")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.dash_ostatki_tovara ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Темп расхода аромата</span>
                                        <div onClick={e => supportChangeValue("temp_rashoda_aromata")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.temp_rashoda_aromata ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>Общая информация по аромату</span>
                                        <div onClick={e => supportChangeValue("all_info_item")} className={"checkbox_polz"}>
                                            <span className={ userRightsArray.all_info_item ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>График новых ароматов</span>
                                        <div onClick={e => supportChangeValue("dash_new_aroma")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.dash_new_aroma ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>
                                    <div className="one_line_aromats">
                                        <span className={"standart_area_title"}>График поставок ароматов</span>
                                        <div onClick={e => supportChangeValue("dash_raznoobr_aroma")}  className={"checkbox_polz"}>
                                            <span className={ userRightsArray.dash_raznoobr_aroma ? "checkbox_quatro active" : "checkbox_quatro" }></span>
                                        </div>
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>
                    <div onClick={e => saveUserRights()} className={"save_aroma_btn"}>Сохранить</div>
                </div>
            );
        } else {
            return (
                <div className={"close_page_for_you"}>
                    <span>Доступ в этот раздел только у администатора.</span>
                </div>
            )
        }
});

export default UserRights;


