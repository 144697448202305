import React, {useContext, useEffect, useState} from 'react';
import "./editSet.css";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {fetchAllAroma} from "../../http/AromaApi";
import {useLocation, useNavigate} from "react-router";
import {Link, useParams} from "react-router-dom";
import {mlData} from "../../http/SupportFunctions";
import {createSet, editeSet, getFullSetInfo, getFullSetInfoForEdit} from "../../http/setApi";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";

const editSet = observer(() => {
    const {settings,user} = useContext(Context);
    const Navigation = useNavigate()

    const [nameSet, setNameSet] = useState("");
    const [openModalFind,setOpenModalFind] = useState(false);
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const [checkActive,setCheckActive] = useState(false);
    const [refreshEffect,setRefreshEffect] = useState(false);
    const onepModalHandler = () => {
        setOpenModalFind(true);
        user.setShadowOverlay(true);
    }

    useEffect(() => {
        if(user.shadowOverlay === false) {
            setOpenModalFind(false)
            setOpenSuccessModal(false)
        }

    },[user.shadowOverlay])



    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const limit = 999;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const [aromaArray, setAromaArray] = useState(null);
    const [refresh,setRefresh] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');

    const [errorMessage, setErrorMessage] = useState("");



    let id = params.id;
    // console.log(params);

    useEffect(() => {


        const delayDebounceFn = setTimeout(() => {
            fetchAllAroma(limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
                if (response.error) {
                    //Ошибка, пока ничего не делаем
                    return false;
                }
                setCount(response.count)
                setAromaArray(response.rows);



            })
        }, 1500)










    }, [page,searchRoleParam,searchQuery,refresh])









    const [setsItemsAdd, setSetsItemsAdd] = useState([]);



    const selectItemsHandler = (item) => {

        let newArray = [];
        if(!setsItemsAdd.length) {
            newArray.push(item);
            setSetsItemsAdd(newArray)
            return false;
        }

        let searchResult = setsItemsAdd.find(oneItem => oneItem.name === item.name);

        if(!searchResult) {
            newArray = [...setsItemsAdd];
            newArray.push(item);
            setSetsItemsAdd(newArray)
            return false;
        }

        if(searchResult) {
            newArray = [...setsItemsAdd];
            let tempArray = [];
            for (var i = newArray.length - 1; i >= 0; --i) {
                if (newArray[i].id == item.id) {

                } else {
                    tempArray.push(newArray[i])

                }
            }
            setSetsItemsAdd(tempArray)
        }

    }




    useEffect(() => {
        if(id) {
            getFullSetInfoForEdit(id).then(response => {
                console.log(response.setData)
                setNameSet(response.setData.name);
                setSetsItemsAdd(response.items);
                setRefreshEffect(!refreshEffect);
                // setMlrefresh(response.mlArray);
                // console.log(response)
                // console.log("setInfo-3333333333333333333333333333333333333333333333333333333333333333333333333333333")
            })
        }


    }, [page,refresh,id])

    const searchNameSupportFunction = (value) => {
        if(!setsItemsAdd.length) {
            return false;
        }
        let search = setsItemsAdd.find(item => item.name === value);
        if(!search) {
            return false;
        }

        return true;
    }
    const deleteItemsHandler = (item) => {
        let newArray = [...setsItemsAdd];
        let searchResult = setsItemsAdd.find(oneItem => oneItem.name === item.name);


        if(searchResult) {
            for (var i = newArray.length - 1; i >= 0; --i) {
                if (newArray[i].name == item.name) {
                    newArray.splice(i,1);
                }
            }
            setSetsItemsAdd(newArray)
        }
    }


    const [MillilitresSelect, setMillilitresSelect] = useState([]);

    const searchSupportFucntion = (value) => {
        if(!MillilitresSelect.length) {
            return false;
        }
        let mlId = MillilitresSelect.find(ml => ml.value === value);
        if(!mlId) {
            return false;
        }

        return true;
    }


    const selectMlHandler = (item) => {


        let newArray = [];
        // if(!MillilitresSelect.length) {
        //     newArray.push(item);
        //     setMillilitresSelect(newArray)
        //     return false;
        // }
        if(mlRefresh) {
            mlRefresh.forEach(ml => {
                if(ml.value <= item.value) {
                    newArray.push(ml);
                }
            })
            setMillilitresSelect(newArray)
        }


        // let searchResult = MillilitresSelect.find(ml => ml.value === item.value);
        //
        // if(!searchResult) {
        //     newArray = [...MillilitresSelect];
        //     newArray.push(item);
        //     setMillilitresSelect(newArray)
        //     return false;
        // }
        //
        // if(searchResult) {
        //     newArray = [...MillilitresSelect];
        //     for (var i = newArray.length - 1; i >= 0; --i) {
        //         if (newArray[i].value == item.value) {
        //             newArray.splice(i,1);
        //         }
        //     }
        //     setMillilitresSelect(newArray)
        // }

    }


    const editSetHandler = () => {
        editeSet(id,nameSet,setsItemsAdd,MillilitresSelect).then(response => {
            console.log(response)
            if(response.error) {
                setErrorMessage(response.error);
                return false;
            }

            if(response.message) {
                setErrorMessage("");
                setOpenSuccessModal(true)
                setSuccessText("Набор успешно отредактирован!")
                // user.setShadowOverlay(true);
                setNameSet("");
                setSetsItemsAdd([]);
                setMillilitresSelect([])
            }
            // window.open(window.location.host+"/adm/sets/"+response.set.id, "_blank")
            Navigation("/adm/sets/"+response.set.id)
        })
    }





    const [mlRefresh,setMlrefresh] = useState(null);

    useEffect(() => {
        let NewMlArrayBeforeCheck = [];
        let result = [];
        if(setsItemsAdd && setsItemsAdd.length) {
            setsItemsAdd.forEach(item => {
                if(item.items_ml && item.items_ml.length) {
                    item.items_ml.forEach(oneMl => {
                        console.log(oneMl)
                        console.log("===========")
                        if(!NewMlArrayBeforeCheck.includes(oneMl.value)){
                            NewMlArrayBeforeCheck.push(oneMl.value);
                        }
                    })
                    // Array.includes()
                }
            })
            NewMlArrayBeforeCheck.forEach(item => {
                if(item != 15) {
                    result.push({value:item})
                }

            })
        }
        setMlrefresh(result);
        console.log('zzzzzzzzzzzzzzzz')
        console.log(result)
    }, [setsItemsAdd,refreshEffect])


    console.log(setsItemsAdd);
    console.log("setsItemsAdd")

    useEffect(() => {
        if(aromaArray && setsItemsAdd && nameSet) {
            setCheckActive(true);
        } else {
            setCheckActive(false)
        }
    },[setsItemsAdd,aromaArray,nameSet])

if(user.getUserRights && user.getUserRights.edit_sets ) {
    return (
        <div className={"add_sets_container"}>
            <SuccessModal isOpen={openSuccessModal} text={successText} />
            <div className={openModalFind ? "div_find_aroma active" : "div_find_aroma"} >
                <span className={"title_modal_fiend"}>Поиск аромата  |  Количество уникальных единиц номенклатур: {setsItemsAdd.length}</span>
                <div className={'seach_container'}>
                    <input
                        className={"search_input"}
                        value={searchQuery ? searchQuery : ""}
                        onChange={ e => setSearchQuery(e.target.value)}
                        placeholder={"Поиск..."} type="text"/>
                    <div className={"abs_lupa"}>
                        <img src="/lupa.png" alt=""/>
                    </div>
                </div>
                <div className={"result_search_container_arm"}>
                    {
                        aromaArray ? aromaArray.map((item, index) =>
                            <div onClick={e => selectItemsHandler(item)} className={searchNameSupportFunction(item.name) ? "aroma_res_item active" : "aroma_res_item"} key={index}>
                                <div>{item.name} - {" " + item && item.brand && item.brand.name ? item.brand.name : ""}</div>
                            </div>
                        ) : 'Ничего не найдено'
                    }
                </div>
            </div>

            <span className={"add_sets_title"}>Редактировать набор</span>
            <div className={"wrapper_area_add_set"}>
                <div className="top_line_set">
                    <div className={"name_set  dlcv"}>Название набора</div>
                    <div className={"count_set_items dlcv"}>Количество уникальных единиц номенклатур: {setsItemsAdd.length}</div>
                    <div className={"variable_set_items dlcv"}>Варианты объемов в наборе</div>
                </div>
                <div className={"params_set_area"}>
                    <div className={"name_set  bnvdb"}>
                        <input className={"set_name_input"} placeholder={"Введите название"} onChange={ e => setNameSet(e.target.value)} value={nameSet} type="text" name="" id=""/>
                    </div>
                    <div className="count_set_items bnvdb">
                        <div className="area_for_compon">
                            {
                                setsItemsAdd ? setsItemsAdd.map((item, index) =>
                                    <div onClick={e => selectItemsHandler(item)} className={"aroma_res_item_area"} key={index}>
                                        <div>{item.name}</div>
                                        <div onClick={e => deleteItemsHandler(item)} className={"delete_aroma_item"}>

                                        </div>
                                    </div>
                                ) : ''
                            }
                        </div>
                        <div onClick={e => onepModalHandler()} className={"pluse_add_new_set"}>

                        </div>
                    </div>
                    <div className="variable_set_items bnvdb">
                        <div className={"checkbox_container_custom"}>
                            {
                                mlRefresh ? mlRefresh.map((item, index) =>
                                    <div  onClick={e => selectMlHandler(item)} key={index} className={ searchSupportFucntion(item.value) ? "ml_wrapper active" : "ml_wrapper"}>
                                        <div className={"box-check"}></div>
                                        <span>{item.value + " мл"}</span>
                                    </div>
                                ) : ""
                            }
                        </div>
                    </div>
                </div>
                <div className={"btn_controll_wrapper"}>
                    <span className={"error_message"}>{errorMessage}</span>
                    <Link to={"/adm/sets/"}  className={"cancel_btn_set"}>Отмена</Link>
                    <div onClick={e => editSetHandler()} className={checkActive ? "set_save_btn" : "set_save_btn false" }>Сохранить</div>
                </div>
            </div>
        </div>
    );
} else {
    return (
        <div className={"close_page_for_you"}>
            <span>Доступ закрыт</span>
        </div>
    )
}


});

export default editSet;

