import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import "./noLinks.css";
import {fetchAllBuys, GetAromaOrSets, saveArticulItemAndSet, saveLinkItemAndSet} from "../../http/AromaApi";
import {useLocation, useNavigate} from "react-router";
import {Link, useParams} from "react-router-dom";
import Pagination from "../../components/UI/Paginstion";
import PaginationV2 from "../../components/UI/Pagination_v2_noLink/PaginationV2";

const NoLinksPage = observer(() => {



    const location = useLocation();
    const Navigate = useNavigate();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')

    let selected = queryParams.get('selected')
    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const limit = 20;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const [buysArray,setBuysArray] = useState(null)
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const [refresh,setRefresh] = useState(false);
    const [noLink,setNoLink] = useState(null)

    const [dataArray,setDataArray] = useState(null);

    useEffect(() => {
        if(!selected) {
            return false;
        }
        GetAromaOrSets(selected,limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setCount(response.count)
            setDataArray(response.rows);
            setNoLink(response.info)

            console.log(response)


        })


    }, [selected,page,searchRoleParam,searchQuery, refresh])


    const handlerChangeStatus = (index) => {
        let NewArray = [...dataArray];
        NewArray[index].status = true;
        setDataArray([...NewArray]);
    }

    const changeFieldHandler = (index,value) => {
        let NewArray = [...dataArray];
        NewArray[index].link = value;
        setDataArray([...NewArray]);
    }

    const handlerSaveChanges = (index,id,type) => {

        saveLinkItemAndSet(dataArray[index],id,type).then(response => {
            console.log(response)
            let NewArray = [...dataArray];
            NewArray[index].status = false;
            setDataArray([...NewArray]);
        })


    }

    const helpChangePage = (param) => {
        Navigate(`/adm/nolink?page=1&selected=${param}`)
        // setRefresh(!refresh)


    }




    return (
        <div className={"no_inf_page_wrapper"}>
            <div className={"title_page_def"}>
                БЕЗ ССЫЛОК
            </div>
            <div className={"wariating_area"}>
                <span onClick={e => (setSearchQuery(""),helpChangePage("aromat"))} className={selected == "aromat" ? "wariat active" : "wariat"}>Ароматы</span>
                <span onClick={e => (setSearchQuery(""),helpChangePage("set"))} className={selected == "set" ? "wariat active" : "wariat"}>Наборы</span>
                <span className={"red_info_link"}>                 Без ссылок -  {noLink ? noLink : "0"};  </span>
            </div>
            {
                <div className={"aromat_wrapper_ar"}>
                    <div className={'seach_container'}>
                        <input
                            className={"search_input"}
                            value={searchQuery ? searchQuery : ""}
                            onChange={ e => setSearchQuery(e.target.value)}
                            placeholder={"Поиск..."} type="text"/>
                        <div className={"abs_lupa"}>
                            <img src="/lupa.png" alt=""/>
                        </div>
                    </div>
                    <div className={"line_items_no_inf"}>
                        <div className={"armset_name stlt strong_nn no_hover"}>Название аромата / набора</div>
                        <div className={"link stlt strong_nn"}>Ссылка на товар на сайте</div>
                        <div className={"deystv stlt strong_nn"}>Действие</div>
                    </div>
                    {
                        dataArray ? dataArray.map((item, index) =>
                            <div className={"line_items_no_inf"} key={index}>
                                {
                                    selected == "aromat" && item.brand &&
                                    <Link to={`/adm/aroma/${item.id}`} target="_blank" className={"armset_name  stlt "}>{item.name + " - " + item.brand.name  }</Link>
                                }
                                {
                                    selected == "set" && item &&
                                    <Link to={`/adm/sets/${item.id}`} target="_blank" className={"armset_name  stlt "}>{item.name}</Link>
                                }
                                {
                                    item.status ?
                                        <input onChange={e => changeFieldHandler(index, e.target.value)} value={item.link ? item.link : ""} className={"link stlt input"} type="text" name="" id=""/> :  <div className={"link  stlt "}>{item.link}</div>
                                }
                                <div className={"deystv stlt "}>
                                    {
                                        item.status ?
                                            <span onClick={e => handlerSaveChanges(index,item.id,selected)} className={"save_btn_inf"}>Сохранить</span>
                                            : <span className={"save_btn_inf"} onClick={e => handlerChangeStatus(index)}>Изменить</span>
                                    }
                                </div>
                            </div>
                        ) : ''
                    }

                </div>
            }
            {

            }
            <PaginationV2 page={page} limit={limit} count={count} selected={selected}/>
        </div>
    );
});

export default NoLinksPage;

