import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import "./Historyall.css";


import {Link, Route, Routes, useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import {changeActiveBrand, fetchAllBrand, fetchDeactiveBrand} from "../../http/BrandApi";
import {changeActiveAroma, fetchAllAroma, fetchAllBuys, getAllHistory, getHistoryAromaById} from "../../http/AromaApi";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";
import AnswerModalDeactive from "../../components/UI/AnswerModalDelete/AnswerModalDeactive";
import Pagination from "../../components/UI/Paginstion";
import {getHumanDate} from "../../http/SupportFunctions";


const AromaHistoryPage = observer(() => {


    const Navigation = useNavigate()
    const {settings,user} = useContext(Context);
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')

    const [statusButton,setStatusButton] = useState("");

    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }
    let id = params.id;



    const limit = 50;

    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const [history, setHistory] = useState(0)
    const [days, setdays] = useState("30d")

    const [refresh,setRefresh] = useState(false);


    const visibleOrNot = (value) => {
        if(value) {
            return "bord"
        } else {
            return "display_none"
        }
    }




    useEffect(() => {
        getAllHistory(limit,(page-1)*limit,true,searchRoleParam,searchQuery,days, statusButton).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            if(response.rows) {
                console.log(response)
                setHistory(response.rows)
                setCount(response.count)
            }



        })


    }, [page,searchRoleParam,searchQuery, refresh,days,statusButton])


    const eventTranslate = (value) => {
        switch (value) {
            case "new_aroma":
                return 'Новый товар';
                break;
            case "new_entranse":
                return  'Новая поставка';
                break;
            case "ost_change":
                return 'Ручное изменение остатка';
                break;
            default:
                return false;
        }
    }

    ///new_aroma
    ///new_entranse
    ///ost_change

    const changeDaysHandler = (value) => {
        setdays(value)
    }

    const changeSearchParam = (value) => {
        setStatusButton(value)
    }

    return (
        <div  className={"main_page_container_post"}>
            {/*<div className={"add_new_btn"} onClick={() => Navigation(-1)}>Вернуться назад</div>*/}
            <div className={"title_page_hist"}>
                История изменения данных
            </div>

            <div className={"wrapper_controll_buttons_history"}>
                <div className={"history_tabs_wrapper"}>
                    <span onClick={e => changeDaysHandler("1d")} className={days == "1d" ? "history_button active" : "history_button"}>Сутки</span>
                    <span onClick={e => changeDaysHandler("7d")} className={days == "7d" ? "history_button active" : "history_button"}>Неделя</span>
                    <span onClick={e => changeDaysHandler("30d")} className={days == "30d" ? "history_button active" : "history_button"}>Месяц</span>
                </div>
                <div className={"flex-container-buttons-wrap"}>
                    <span onClick={e => changeSearchParam("")} className={statusButton ==  "" ? "standart_status_button active" : "standart_status_button"}>Без фильтра</span>
                    <span onClick={e => changeSearchParam("new_aroma")} className={statusButton ==  "new_aroma" ? "standart_status_button active" : "standart_status_button"}>Новый товар</span>
                    <span onClick={e => changeSearchParam("new_entranse")} className={statusButton ==  "new_entranse" ? "standart_status_button active" : "standart_status_button"}>Новая поставка</span>
                    <span onClick={e => changeSearchParam("ost_change")} className={statusButton ==  "ost_change" ? "standart_status_button active" : "standart_status_button"}>Изменение остатка</span>

                </div>
            </div>

            {
                history ? history.map((item, index) =>
                    <div>
                        <div className={"stroke_page_history first_row history_all"} >
                            <div className={visibleOrNot(item.itemId)+" b_name strong"}>Товар</div>
                            {/*<div className={visibleOrNot(item.nameField)+" b_name strong"}>Событие</div>*/}
                            <div className={visibleOrNot(item.eventType)+" b_name strong"}>Тип события</div>
                            <div className={visibleOrNot(item.price_s)+" b_name strong"}>Цена поставки ₽</div>
                            <div className={visibleOrNot(item.volume)+" b_name strong"}>Обьем</div>
                            <div className={visibleOrNot(item.procent)+" b_name strong"}>Процент изменения цены</div>
                            <div className={visibleOrNot(item.count)+" b_name strong"}>Кол-во</div>
                            <div className={visibleOrNot(item.oldValue)+" b_value strong"}>Старое значение</div>
                            <div className={visibleOrNot(item.nevValue)+" b_value strong"}>Новое значение</div>
                            <div className={visibleOrNot(item.userId)+" user_id strong"}>ID пользователя</div>
                            <div className={visibleOrNot(item.userName)+" user_login strong"}>Логин пользователя</div>
                            <div className={visibleOrNot(item.reason)+" reason_change strong"}>Причина изменения</div>
                            <div className={visibleOrNot(item.createdAt)+" create_date strong"}>Дата изменения</div>
                        </div>
                        <div className={"stroke_page_history"} key={index}>
                            <Link to={`/adm/aroma/${item.itemId}`} target={"_blank"} className={visibleOrNot(item.itemId)+" b_name strong"}>{item.itemName ? item.itemName : item.itemId}</Link>
                            {/*<div className={visibleOrNot(item.nameField)+" b_name strong"}>{item.nameField ? item.nameField : ""}</div>*/}
                            <div className={visibleOrNot(item.eventType)+" b_name strong"}>{item.eventType ? eventTranslate(item.eventType) : ""}</div>
                            <div className={visibleOrNot(item.price_s)+" b_name strong"}>{item.price_s ? (item.price_s*settings.getRubCurse).toFixed(2) : ""}</div>
                            <div className={visibleOrNot(item.volume)+" b_name strong"}>{item.volume ? item.volume : ""}</div>
                            <div className={visibleOrNot(item.procent)+" b_name strong"}>{item.procent ? item.procent+ "%" : ""}</div>
                            <div className={visibleOrNot(item.count)+" b_name strong"}>{item.count ? item.count : ""}</div>
                            <div className={visibleOrNot(item.oldValue)+" b_value strong"}>{item.oldValue ? item.oldValue : ""}</div>
                            <div className={visibleOrNot(item.nevValue)+" b_value strong"}>{item.nevValue ? item.nevValue : ""}</div>
                            <div className={visibleOrNot(item.userId)+" user_id strong"}>{item.userId ? item.userId : ""}</div>
                            <div className={visibleOrNot(item.userName)+" user_login strong"}>{item.userName ? item.userName : ""}</div>
                            <div className={visibleOrNot(item.reason)+" reason_change strong"}>{item.reason ? item.reason : ""}</div>
                            <div className={visibleOrNot(item.createdAt)+" create_date strong"}>{item.createdAt ? getHumanDate(item.createdAt) : ""}</div>
                        </div>
                    </div>

                ) : ''
            }

            <Pagination page={page} limit={limit} count={count}/>


        </div>
    );
});

export default AromaHistoryPage;



