import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import "./NoArtPage.css";
import {
    fetchAllBuys,
    GetAromaOrSets,
    GetAromaOrSetsArt,
    saveArticulItemAndSet,
    saveArticulItemAndSetArt
} from "../../http/AromaApi";
import {useLocation, useNavigate} from "react-router";
import {Link, useParams} from "react-router-dom";
import Pagination from "../../components/UI/Paginstion";
import PaginationV2 from "../../components/UI/Pagination_v2_noLink/PaginationV2";

const NoArtuculPage = observer(() => {

    // const [selected,setSelected] = useState("aromat");

    const location = useLocation();
    const Navigate = useNavigate();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')
    let selected = queryParams.get('selected')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const helpChangePage = (param) => {
        Navigate(`/adm/noart?page=1&selected=${param}`)
        // setRefresh(!refresh)


    }


    const limit = 20;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const [buysArray,setBuysArray] = useState(null)
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const [refresh,setRefresh] = useState(false);


    const [dataArray,setDataArray] = useState(null);
    const [noArt,setNoArt] = useState(null)
    useEffect(() => {
        if(!selected) {
            return false;
        }
        GetAromaOrSetsArt(selected,limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setCount(response.count)
            setDataArray(response.rows);
            setNoArt(response.noArt)
            console.log(response)


        })


    }, [selected,page,searchRoleParam,searchQuery, refresh])


    const handlerChangeStatus = (index) => {
        let NewArray = [...dataArray];
        NewArray[index].status = true;
        setDataArray([...NewArray]);
    }

    const changeFieldHandler = (index,value) => {
        let NewArray = [...dataArray];
        NewArray[index].articul = value;
        setDataArray([...NewArray]);
    }

    const handlerSaveChanges = (index,id,type) => {

        saveArticulItemAndSetArt(dataArray[index],id,type).then(response => {
            console.log(response)
            let NewArray = [...dataArray];
            NewArray[index].status = false;
            setDataArray([...NewArray]);
        })


    }




    return (
        <div className={"no_inf_page_wrapper"}>
            <div className={"title_page_def"}>
                БЕЗ АРТИКУЛОВ
            </div>
            <div className={"wariating_area"}>
                <span onClick={e => (setSearchQuery(""),helpChangePage("aromat"))} className={selected == "aromat" ? "wariat active" : "wariat"}>Ароматы</span>
                <span onClick={e => (setSearchQuery(""),helpChangePage("set"))} className={selected == "set" ? "wariat active" : "wariat"}>Наборы</span>
                <span className={"red_info_link"}>                 Без артикулов -  {noArt ? noArt : "0"};  </span>
            </div>
            {
                <div className={"aromat_wrapper_ar"}>
                    <div className={'seach_container'}>
                        <input
                            className={"search_input"}
                            value={searchQuery ? searchQuery : ""}
                            onChange={ e => setSearchQuery(e.target.value)}
                            placeholder={"Поиск..."} type="text"/>
                        <div className={"abs_lupa"}>
                            <img src="/lupa.png" alt=""/>
                        </div>
                    </div>
                    <div className={"line_items_no_inf"}>
                        <div className={"armset_name stlt strong_nn no_hover"}>Название аромата / набора</div>
                        <div className={"objm stlt strong_nn"}>обьем, мл.</div>
                        <div className={"artk stlt strong_nn"}>Артикул</div>
                        <div className={"deystv stlt strong_nn"}>Действие</div>
                    </div>
                    {
                        dataArray ? dataArray.map((item, index) =>
                                <div className={"line_items_no_inf"} key={index}>
                                    {
                                        selected == "aromat" &&
                                        <Link to={`/adm/aroma/${item.id}`} target="_blank"  className={"armset_name  stlt "}>{item.name + " бренд - " + item.brandName }</Link>
                                    }
                                    {
                                        selected == "set" &&
                                        <Link to={`/adm/sets/${item.id}`} target="_blank" className={"armset_name  stlt "}>{item.name}</Link>
                                    }
                                    <div  className={"objm stlt "}>{item.value}</div>
                                    {
                                        item.status ?
                                            <input onChange={e => changeFieldHandler(index, e.target.value)} value={item.articul ? item.articul : ""} className={"artk stlt input"} type="text" name="" id=""/> :  <div className={"artk  stlt "}>{item.articul}</div>
                                    }
                                    <div className={"deystv stlt "}>
                                        {
                                            item.status ?
                                                <span onClick={e => handlerSaveChanges(index,item.id,selected)} className={"save_btn_inf"}>Сохранить</span>
                                                : <span className={"save_btn_inf"} onClick={e => handlerChangeStatus(index)}>Изменить</span>
                                        }
                                    </div>
                                </div>
                        ) : ''
                    }

                </div>
            }
            {

            }
            <PaginationV2 page={page} limit={limit} count={count} selected={selected}/>
        </div>
    );
});

export default NoArtuculPage;



