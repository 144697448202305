import {$authHost,$host} from "./index";



export const createAromaAndPost = async (
    brand, name, ml, volume, count, price_S, price_R, postavshik, price_S_ml, price_R_ml, comment,user
    ) => {
    const {data} = await $authHost.post('api/aroma/create', {brand, name, ml, volume, count, price_S, price_R, postavshik, price_S_ml, price_R_ml, comment,user})
    return data;
}


export const createEntranceForAroma = async (
    volume, count, price_S, price_R, postavshik, price_S_ml, price_R_ml, comment,user, aromaId
) => {
    const {data} = await $authHost.post('api/aroma/createentranceforaroma',
        {volume, count, price_S, price_R, postavshik, price_S_ml, price_R_ml, comment,user, aromaId})
    return data;
}

export const fetchAllAroma = async (limit,offset,getCount,searchRoleParam,searchQuery,brand) => {
    const {data} = await $authHost.post('api/aroma/fetchallaroma',{limit,offset, getCount,searchRoleParam,searchQuery,brand});

    return data;
}

export const fetchWrongItems = async (limit,offset,getCount,searchRoleParam,searchQuery) => {
    const {data} = await $authHost.post('api/aroma/fetchwrongitems',{limit,offset, getCount,searchRoleParam,searchQuery});

    return data;
}

export const fetchEntranceById = async (limit,offset,getCount,searchRoleParam,searchQuery,id) => {
    const {data} = await $authHost.post('api/aroma/getentrancebyid',{limit,offset, getCount,searchRoleParam,searchQuery,id});

    return data;
}

export const getHistoryAromaById = async (id,limit,offset, getCount,searchRoleParam,searchQuery) => {
    const {data} = await $authHost.post('api/aroma/gethistoryaromabyid',{id,limit,offset, getCount,searchRoleParam,searchQuery});
    return data;
}

export const getAllHistory = async (limit,offset, getCount,searchRoleParam,searchQuery,days,statusButton) => {
    const {data} = await $authHost.post('api/aroma/getallhitory',{limit,offset, getCount,searchRoleParam,searchQuery,days,statusButton});
    return data;
}

export const getOstatkiByFilter = async (limit,offset,getCount,searchRoleParam,searchQuery,alterSearchQuery) => {
    const {data} = await $authHost.post('api/aroma/ostatkibyfilter',{limit,offset, getCount,searchRoleParam,searchQuery,alterSearchQuery});

    return data;
}

export const getForecast = async (limit,offset,getCount,searchRoleParam,searchQuery,alterSearchQuery) => {
    const {data} = await $authHost.post('api/aroma/forecast',{limit,offset, getCount,searchRoleParam,searchQuery,alterSearchQuery});

    return data;
}

export const getRemains = async (limit,offset,getCount,searchRoleParam,searchQuery) => {
    const {data} = await $authHost.post('api/aroma/getremains',{limit,offset, getCount,searchRoleParam,searchQuery});

    return data;
}

export const fetchRashodByTime = async (limit,offset,getCount,searchRoleParam,searchQuery,dateInterval,sortSelector) => {
    const {data} = await $authHost.post('api/aroma/temprashoda',{limit,offset, getCount,searchRoleParam,searchQuery,dateInterval,sortSelector});

    return data;
}

export const getSetsByAromaId = async (id) => {
    const {data} = await $authHost.post('api/aroma/getsetsbyaromaid', {id})
    return data;
}

export const changeButtonStatus = async (id,status,date) => {
    const {data} = await $authHost.post('api/aroma/changebuttonstatus', {id,status,date})
    return data;
}


export const changeActiveAroma = async (id) => {
    const {data} = await $authHost.post('api/aroma/changeactive', {id})
    return data;
}

export const changeAromaName = async (id, name,notes,searchName) => {
    const {data} = await $authHost.post('api/aroma/changename', {id,name,notes,searchName})
    return data;
}

export const changeActiveEntrance = async (id) => {
    const {data} = await $authHost.post('api/aroma/changeactiveentrance', {id})
    return data;
}

export const getAromaAndEntrance = async (id) => {
    const {data} = await $authHost.post('api/aroma/aromaandentrance', {id})
    return data;
}

export const updateOstatokById = async (item,userdata,reason) => {
    const {data} = await $authHost.post('api/aroma/updateostatokbyid', {item,userdata,reason})
    return data;
}


export const createNewChillProducts = async (item, selected) => {
    const {data} = await $authHost.post('api/aroma/createnewchillproducts', {item,selected})
    return data;
}

export const updateEntrance = async (item,newpost) => {
    const {data} = await $authHost.post('api/aroma/updateentrance', {item,newpost})
    return data;
}
export const updateBrandByAromaId = async (id,brand,searchName) => {
    const {data} = await $authHost.post('api/aroma/updatebramdbyaromaid', {id,brand,searchName})
    return data;
}

export const getLastEntranceAromaByAromaId = async (id) => {
    const {data} = await $authHost.post('api/aroma/getlastentrancebyaromaid', {id})
    return data;
}


export const createBuys = async (orderId,date,name,articul,volume,count, price,aromaId) => {
    const {data} = await $authHost.post('api/aroma/createbuys', {orderId,date,name,articul,volume,count, price,aromaId})
    return data;
}


export const fetchAllBuys = async (limit,offset,getCount,searchRoleParam,searchQuery,id) => {
    const {data} = await $authHost.post('api/aroma/fetchallbuys',{limit,offset, getCount,searchRoleParam,searchQuery,id});
    return data;
}

// export const fetchBuysById = async (limit,offset,getCount,searchRoleParam,searchQuery,id) => {
//     const {data} = await $authHost.post('api/aroma/fetchbuysbyid',{limit,offset, getCount,searchRoleParam,searchQuery,id});
//     return data;
// }
export const fetchCountsForIndicators = async () => {
    const {data} = await $authHost.post('api/aroma/getcountsforindicator',{});
    return data;
}


export const getLastEntrance = async (aromaId) => {
    const {data} = await $authHost.post('api/aroma/getlastentrance', {aromaId})
    return data;
}

export const getRashod = async (id) => {
    const {data} = await $authHost.post('api/aroma/getrashod', {id})
    return data;
}


export const GetAllAromaInformation = async () => {
    const {data} = await $authHost.post('api/aroma/getallinfo', {})
    return data;
}


export const GetAromaItemsDiagramm = async (selectData) => {
    const {data} = await $authHost.post('api/aroma/getaromaitemsdiagramm', {selectData})
    return data;
}

export const GetAromaEntranceDiagramm = async (selectData) => {
    const {data} = await $authHost.post('api/aroma/getaromaentrancediagramm', {selectData})
    return data;
}

export const GetAromaDivercityDiagramm = async (selectData) => {
    const {data} = await $authHost.post('api/aroma/divercity', {selectData})
    return data;
}


export const GetAromaOrSets = async (selected,limit,offset, getCount,searchRoleParam,searchQuery,sortSelector) => {
    const {data} = await $authHost.post('api/aroma/getaromaorsets', {selected,limit,offset, getCount,searchRoleParam,searchQuery,sortSelector})
    return data;
}
export const GetAromaOrSetsArt= async (selected,limit,offset, getCount,searchRoleParam,searchQuery,sortSelector) => {
    const {data} = await $authHost.post('api/aroma/getaromaorsetsart', {selected,limit,offset, getCount,searchRoleParam,searchQuery,sortSelector})
    return data;
}
export const saveNewLink = async (id,value) => {
    const {data} = await $authHost.post('api/aroma/savenewlink', {id,value})
    return data;
}

export const saveArticulItemAndSet = async (item,id,type) => {
    const {data} = await $authHost.post('api/aroma/saveartitemandset', {item,id,type})
    return data;
}
export const saveArticulItemAndSetArt = async (item,id,type) => {
    const {data} = await $authHost.post('api/aroma/saveartitemandsetart', {item,id,type})
    return data;
}
export const saveLinkItemAndSet = async (item,id,type) => {
    const {data} = await $authHost.post('api/aroma/savelinkitemandset', {item,id,type})
    return data;
}

export const saveSettings = async (params) => {
    const {data} = await $authHost.post('api/aroma/savesettings', {params})
    return data;
}
export const getSettings = async () => {
    const {data} = await $authHost.post('api/aroma/getsettings', {})
    return data;
}

export const startMathAroma = async () => {
    const {data} = await $authHost.post('api/aroma/startmath', {})
    return data;
}

export const saveRemain = async (id,value) => {
    const {data} = await $authHost.post('api/aroma/saveremain', {id,value})
    return data;
}

export const saveTableOptions = async (aroma,childproducts,selectedStopper) => {
    const {data} = await $authHost.post('api/aroma/savetableoptions', {aroma,childproducts,selectedStopper})
    return data;
}

export const addImages = async (fields,articul,resizePreset) => {

    const {data} = await $authHost.post("api/aroma/addimages", fields)
    if(data.error) {
        return data;
    }

    if (data.fileName) {
        const dataTransfer = await transferImage(data.fileName,articul,resizePreset);
        return dataTransfer;
    }
}


export const addFile = async (fields,pathFile) => {

    const {data} = await $authHost.post("api/aroma/addfile", fields)
    if(data.error) {
        return data;
    }

    if (data.fileName) {
        const dataTransfer = await transferImage(data.fileName,pathFile);
        return dataTransfer;
    }
}

export const transferImage = async (fileName,articul,resizePreset) => {
    const {data} = await $authHost.post('api/aroma/transferimage', {fileName,articul,resizePreset})
    if(data.error) {
        return data;
    }
    return data;
}


export const setSelectedImg = async (id,path) => {
    const {data} = await $authHost.post('api/aroma/selectedimg', {id,path})
    return data;
}


export const readDir = async (selectedPath) => {
    const {data} = await $authHost.post('api/aroma/readdir', {selectedPath})
    return data;
}

export const createFolder = async (selectedPath,name) => {
    const {data} = await $authHost.post('api/aroma/createfolder', {selectedPath,name})
    return data;
}


export const deleteFolderOrFile = async (selectedPath,name, type) => {
    const {data} = await $authHost.post('api/aroma/deletefolderorfile', {selectedPath,name,type})
    return data;
}

export const renameFolderAndFile = async (selectedPath,name, newName) => {
    const {data} = await $authHost.post('api/aroma/renamefolderandfile', {selectedPath,name,newName})
    return data;
}


