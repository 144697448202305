import React, {useContext, useEffect, useState} from 'react';
import "./forecast.css"
import {observer} from "mobx-react-lite";
import {fetchAllSet} from "../../http/setApi";
import {useLocation} from "react-router";
import {Link, useParams} from "react-router-dom";
import {getForecast, getOstatkiByFilter} from "../../http/AromaApi";
import Pagination from "../../components/UI/Paginstion";
import DashboardControllerPage from "../../components/DashboardControllerPage/DashboardControllerPage";
import {Context} from "../../index";


const Forecast = observer(() => {



    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState(50000);
    const {settings,user} = useContext(Context);

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const [allItems,setAllItems] = useState(0)
    const [postavArray, setPostavArray] = useState(null);
    const [refresh,setRefresh] = useState(false);
    /////
    const [statusButton, setStatusButton] = useState("")
    let limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)

    useEffect(() => {
        getForecast(limit,(page-1)*limit,true,searchRoleParam,searchQuery, statusButton).then(response => {
            setCount(response.count);
            setAllItems(response.data);
            if(!response.data.length) {
                page = 0;
            }
            console.log(response)
        })

    }, [searchQuery,page,statusButton])


    const textStatusConverter = (value) => {
        switch (value) {
            case "buy":
                return 'Заказали';
                break;
            case "search":
                return  'В поиске';
                break;
            case "no_find":
                return 'Пока нигде нет';
                break;
            case "no_buy":
                return 'Больше не покупаем';
                break;
            default:
                return false;
        }
    }

    const changeSearchParam = (value) => {
        setStatusButton(value)
    }

    const getForecastSupport = (item,count) => {
        let forecast = item.consumption;
        let ostatok = item.ostatok;
        let newForecast = forecast*count;
        if(newForecast < ostatok) {
            return "Достаточно";
        }
        // console.log(forecast);
        // console.log(----);
        // console.log(ostatok);
        return newForecast*1-ostatok*1;
    }

    return (
        <div className={"all_ml_ost_page"}>
            <span className={"name_page_default"}>Дашборд - Остатки</span>
            <DashboardControllerPage page={"forecast"} />

            {
                user.getUserRights && user.getUserRights.dash_ostatki_tovara &&
                <div className={"wrapper_controll_buttons forecast"}>
                    <div className={"flex-container-buttons-wrap"}>
                        <span onClick={e => changeSearchParam("")} className={statusButton ==  "" ? "standart_status_button active" : "standart_status_button"}>Без фильтра</span>
                        <span onClick={e => changeSearchParam("buy")} className={statusButton ==  "buy" ? "standart_status_button active" : "standart_status_button"}>Заказали</span>
                        <span onClick={e => changeSearchParam("search")} className={statusButton ==  "search" ? "standart_status_button active" : "standart_status_button"}>В поиске</span>
                        <span onClick={e => changeSearchParam("no_find")} className={statusButton ==  "no_find" ? "standart_status_button active" : "standart_status_button"}>Пока нигде нет</span>
                        <span onClick={e => changeSearchParam("no_buy")} className={statusButton ==  "no_buy" ? "standart_status_button active" : "standart_status_button"}>Больше не покупаем</span>
                    </div>
                </div>

            }


            {
                user.getUserRights && user.getUserRights.dash_ostatki_tovara &&
                <div className={"forecast_wrapper_table"}>
                    {/*<div className={"solid custom_remains top_ltc"}> &lt; {searchQuery ? searchQuery : 0}</div>*/}
                    <div className={"wrapper_list_remains custom_remains"}>

                        <div className={"name_remain_block"}>Название</div>
                        <div className={"count_remain_block"}>Фактический остаток</div>
                        <div className={"count_remain_block"}>Статус</div>
                        <div className={"count_remain_block"}>Запас на 1 мес</div>
                        <div className={"count_remain_block"}>Запас на 2 мес</div>
                        <div className={"count_remain_block"}>Запас на 3 мес</div>
                    </div>
                    {
                        allItems ? allItems.map((item, index) =>
                            <div className={"one_remain_line dash"}>
                                <Link to={`/adm/aroma/${item.id}`} className={"name_remain_block"} key={index}>
                                    {item.name}
                                </Link>
                                <div className={"count_remain_block"}>{item.ostatok}</div>

                                <div className={"count_remain_block button"}>
                                    <span className={textStatusConverter(item.statusButton) ? "no_left standart_status_button" : "none"}>{textStatusConverter(item.statusButton)}</span>
                                </div>
                                <div className={"count_remain_block date"}>{getForecastSupport(item,1)}</div>
                                <div className={"count_remain_block date"}>{getForecastSupport(item,2)}</div>
                                <div className={"count_remain_block date"}>{getForecastSupport(item,3)}</div>

                            </div>
                        ) : ''
                    }
                    {
                        user.getUserRights && user.getUserRights.dash_ostatki_tovara &&

                        <div className={"custom_pag_container forecast"}>
                            <Pagination page={page} limit={limit} count={count}/>
                        </div>
                    }
                </div>

            }



            {
                user.getUserRights && !user.getUserRights.dash_ostatki_tovara &&

                <div className={"close_page_for_you"}>
                    <span>Доступ закрыт</span>
                </div>
            }



        </div>
    );



});





export default Forecast;

