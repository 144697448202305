import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import './allParams.css';
import {Link} from "react-router-dom";
import {DataArray} from "../../utils/consts";
import {getSettings, saveSettings, startMathAroma} from "../../http/AromaApi";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";
import {Context} from "../../index";

const AllParamsPage = observer(() => {

    const [isRedact,setIsRedact] = useState(false);
    const [globalCoef,setGlobalCoef] = useState(null);
    const {settings,user} = useContext(Context);


    const [params,setParams] = useState({
        rubCurse:0,
        global_coef:0,
        p_1:0,
        p_2:0,
        p_3:0,
        p_5:0,
        p_10:0,
        p_15:0,
        etiketka_flac:0,
        etiketka_box:0,
        box_scotch:0,
        job_upakovka:0,
        paket:0,
        instrum:0,
        arenda:0,
        nacenka_na_edenicy_flacona: 0,
        nacenka_na_rashodniki:0,
        obshie_rashody: null,
        nacen_for_site_price: null,
        opt_nacen: null,
        itog_rashod: null,
    })

    const [nacenkaNaOstatok,setNacenkaNaOstatok] = useState(  [
            {
                value:50,
                1:false,
                2:false,
                3:false,
                5:false,
                10:false,
                15:false,
                nacen:0,
        },
        {
                value:30,
                1:false,
                2:false,
                3:false,
                5:false,
                10:false,
                15:false,
                nacen:0,
        },
        {
                value:20,
                1:false,
                2:false,
                3:false,
                5:false,
                10:false,
                15:false,
                nacen:0,
        },
        {
                value:10,
                1:false,
                2:false,
                3:false,
                5:false,
                10:false,
                15:false,
                nacen:0,
        },
       {
               value:5,
                1:false,
                2:false,
                3:false,
                5:false,
                10:false,
                15:false,
                nacen:0,
        }
    ])


    const [autoChangeActiveSet,setAutoChangeActiveSet] = useState(  [
        {
            value:50,
            1:false,
            2:false,
            3:false,
            5:false,
            10:false,
            15:false,
            nacen:0,
        },
        {
            value:30,
            1:false,
            2:false,
            3:false,
            5:false,
            10:false,
            15:false,
            nacen:0,
        },
        {
            value:20,
            1:false,
            2:false,
            3:false,
            5:false,
            10:false,
            15:false,
            nacen:0,
        },
        {
            value:10,
            1:false,
            2:false,
            3:false,
            5:false,
            10:false,
            15:false,
            nacen:0,
        },
        {
            value:5,
            1:false,
            2:false,
            3:false,
            5:false,
            10:false,
            15:false,
            nacen:0,
        }
    ])





    const [nacenForSitePrice,setNacenForSitePrice] = useState(  [
        {
            value:100,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:150,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:200,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:250,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:300,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:400,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:500,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:600,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:700,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:800,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:1000,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:1200,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:1500,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:2000,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:2500,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:3000,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:3500,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:4500,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:6000,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:9000,
            nacen:0,
            correct:0,
            site_price:0,
        },
        {
            value:15000,
            nacen:0,
            correct:0,
            site_price:0,
        },
    ])

    const [optNacen,setOptNacen] = useState(  [
        {
            start: 0,
            stop: 50,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },
        {
            start: 50,
            stop: 150,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },
        {
            start: 150,
            stop: 250,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },
        {
            start: 250,
            stop: 400,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },
        {
            start: 400,
            stop: 800,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },
        {
            start: 800,
            stop: 1200,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },
        {
            start: 1200,
            stop: 2000,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },
        {
            start: 2000,
            stop: 4000,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },
        {
            start: 4000,
            stop: 8000,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },
        {
            start: 8000,
            stop: 999999,
            ml_1: 0,
            ml_2: 0,
            ml_3: 0,
            ml_5: 0,
            ml_10:0,
            ml_15:0,
        },

    ])

    const [itogRashod,setItogRashod] = useState(  [
        {
            value: 1,
            flak_upak: 0,
            trud: 0,
            itogo: 0,
        },
        {
            value: 2,
            flak_upak: 0,
            trud: 0,
            itogo: 0,
        },
        {
            value: 3,
            flak_upak: 0,
            trud: 0,
            itogo: 0,
        },
        {
            value: 5,
            flak_upak: 0,
            trud: 0,
            itogo: 0,
        },
        {
            value: 10,
            flak_upak: 0,
            trud: 0,
            itogo: 0,
        },
        {
            value: 15,
            flak_upak: 0,
            trud: 0,
            itogo: 0,
        },

    ])

    const checkboxChangeHandler = (index,value) => {
        let newArray =  [...nacenkaNaOstatok];
       newArray[index][value] = !newArray[index][value];
        setNacenkaNaOstatok(newArray)
    }

    const checkboxChangeHandlerSet = (index,value) => {
        let newArray =  [...autoChangeActiveSet];
        newArray[index][value] = !newArray[index][value];
        setAutoChangeActiveSet(newArray)
    }
    const nacenChangeHandler = (index,value) => {
        let newArray =  [...nacenkaNaOstatok];
        newArray[index].nacen = value;
        setNacenkaNaOstatok(newArray)
    }


    // Таблица наценок
    const nacenTableChangeHandler = (index,value) => {
        let newArray =  [...nacenForSitePrice];
        newArray[index].nacen = value;
        setNacenForSitePrice(newArray)
    }

    const correctTableChangeHandler = (index,value) => {
        let newArray =  [...nacenForSitePrice];
        newArray[index].correct = value;
        setNacenForSitePrice(newArray)
    }
    const sitePriceTableChangeHandler = (index,value) => {
        let newArray =  [...nacenForSitePrice];
        newArray[index].site_price = value;
        setNacenForSitePrice(newArray)
    }

    // var one = storage["id1"]["one"];  оптовые наценки
    const optTableChangeField = (index,value,nameField) => {
        let newArray =  [...optNacen];
        newArray[index][nameField] = value;
        setOptNacen(newArray)
    }

    /// Итоговый расход

    const itogRashodChangeField = (index,value,nameField) => {
        let newArray =  [...itogRashod];
        newArray[index][nameField] = value;
        setItogRashod(newArray)
    }


    const saveTable = () => {
            let data = {...params};
        data = {...data, obshie_rashody:JSON.stringify(nacenkaNaOstatok)}
        data = {...data, nacen_for_site_price:JSON.stringify(nacenForSitePrice)}
        data = {...data, opt_nacen:JSON.stringify(optNacen)}
        data = {...data, itog_rashod:JSON.stringify(itogRashod)}
        data = {...data, autoChangeSet:JSON.stringify(autoChangeActiveSet)}
        console.log(data)
        saveSettings(data).then(response => {
            console.log(response)
            setOpenSuccessModal(true);
            setSuccessText("Настройки таблицы сохранены")
            user.setShadowOverlay(true)
        })

    }


    useEffect( () => {
        getSettings().then(response => {
            console.log(response)
            console.log("GET SETTINGS")
            setParams(response.data);
            setNacenkaNaOstatok(JSON.parse(response.data.obshie_rashody))
            setNacenForSitePrice(JSON.parse(response.data.nacen_for_site_price))
            setOptNacen(JSON.parse(response.data.opt_nacen))
            setItogRashod(JSON.parse(response.data.itog_rashod))
            if(JSON.parse(response.data.autoChangeSet)) {
                setAutoChangeActiveSet(JSON.parse(response.data.autoChangeSet))
                console.log(response.data.autoChangeSet)
            }

        })
    }, [])


    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');

    const clickStartMath = () => {
        startMathAroma().then(response => {
            // console.log(response)

        })

        setOpenSuccessModal(true);
        setSuccessText("Успешно запущено! Результаты обновятся в течении минуты.")
        user.setShadowOverlay(true)
    }


    useEffect(() => {
        if(user.shadowOverlay === false) {

            setOpenSuccessModal(false)
        }

    },[user.shadowOverlay])

    // console.log(user.getUserRights());
    // console.log("RIGHTS")


            return (
                <div className={"wrapper_params_tt"}>
                    <SuccessModal isOpen={openSuccessModal} text={successText} />

                    <div className={"top_line_params"}>
                        <div className={"titlepage"}>Справочники</div>
                        {/*<span className={"heading_btn_add_user"}>Добавить пользователя</span>*/}
                        <span onClick={e => setIsRedact(!isRedact)} className={ !isRedact ?  "redact_params active" : "redact_params"}> Редактировать справочник </span>
                    </div>
                    <div className={"retls_param"}>
                        <div className={ user.getUserRights && user.getUserRights.guide_cash_view  ? "wrapper_for_settings" : "wrapper_for_settings display_none" }>
                            <div className={ user.getUserRights && user.getUserRights.guide_cash_edit  ? "wrapper_div_course " : "wrapper_div_course pointer_events_none" }>
                                <div className={"valute"}>Курс валют:</div>
                                <div className={"one_kkkd"}>1</div>
                                <div className={"one_kd_valt"}>USD</div>
                                <div className={"arrows_parrent"}>
                                    <div className={"line_arrow_parrent"}></div>
                                    <div className={"arrows_child"}>

                                    </div>
                                </div>

                                <input readOnly={!isRedact} value={params.rubCurse ? params.rubCurse : ""} onChange={e => setParams({...params, rubCurse: e.target.value})} className="one_kkkd rub"/>
                                <div className={"one_kd_valt"}>RUB</div>
                            </div>
                        </div>

                        <div onClick={e => clickStartMath()} className={"calculate_all"}>Пересчитать все товары </div>

                        <div className={ user.getUserRights && user.getUserRights.guide_global_nac_view  ? "wrapper_for_settings" : "wrapper_for_settings display_none" }>
                            <div className={user.getUserRights && user.getUserRights.guide_global_nac_edit  ? "swapper_tes" : "swapper_tes pointer_events_none"}>
                                <span className={"askri_nac"}> Глобальный коэффициент наценки: </span>

                                <input className={"glob_cof"} readOnly={!isRedact}  onChange={e => setParams({...params, global_coef:e.target.value})} value={params && params.global_coef ? params.global_coef : ""} type="text"/>

                            </div>
                        </div>

                    </div>


                    <div className={"area_for_tables"}>
                        {/*/////////////// ПОТЕРИ //////////*/}
                        <div className={ user.getUserRights && user.getUserRights.guide_nacenki_za_potery_view  ? "wrapper_for_settings" : "wrapper_for_settings display_none" }>
                            <div className={ user.getUserRights && user.getUserRights.guide_nacenki_za_potery_edit  ? "box_bb_container" : "box_bb_container pointer_events_none"}>
                                <span className={"titlebox"}>Наценка за потери</span>
                                <div className={"box_table_bb"}>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Наценка за потери 1 мл</span>
                                        <input   onChange={e => setParams({...params, p_1: e.target.value})} readOnly={!isRedact} value={params.p_1 ? params.p_1 : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Наценка за потери 2 мл</span>
                                        <input  onChange={e => setParams({...params, p_2: e.target.value})} readOnly={!isRedact} value={params.p_2? params.p_2 : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Наценка за потери 3 мл</span>
                                        <input  onChange={e => setParams({...params, p_3: e.target.value})} readOnly={!isRedact} value={params.p_3 ? params.p_3 : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Наценка за потери 5 мл</span>
                                        <input  onChange={e => setParams({...params, p_5: e.target.value})} readOnly={!isRedact} value={params.p_5 ? params.p_5 : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Наценка за потери 10 мл</span>
                                        <input  onChange={e => setParams({...params, p_10: e.target.value})} readOnly={!isRedact} value={params.p_10 ? params.p_10 : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Наценка за потери 15 мл</span>
                                        <input  onChange={e => setParams({...params, p_15: e.target.value})} readOnly={!isRedact} value={params.p_15 ? params.p_15 : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*///ОБЩИЕ РАСХОДЫ////*/}
                        <div className={ user.getUserRights && user.getUserRights.guide_all_rashod_view  ? "wrapper_for_settings" : "wrapper_for_settings display_none" }>

                            <div className={ user.getUserRights && user.getUserRights.guide_all_rashod_edit  ? "box_bb_container blc" : "box_bb_container blc pointer_events_none"}>
                                <span className={"titlebox"}>Общие расходы</span>
                                <div className={"box_table_bb"}>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Этикетка на флакон</span>
                                        <input  onChange={e => setParams({...params, etiketka_flac: e.target.value})} readOnly={!isRedact} value={params.etiketka_flac ? params.etiketka_flac : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Оплата менеджеру</span>
                                        <input  onChange={e => setParams({...params, etiketka_box: e.target.value})} readOnly={!isRedact} value={params.etiketka_box ? params.etiketka_box : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Коробка почтовая скотч</span>
                                        <input  onChange={e => setParams({...params, box_scotch: e.target.value})} readOnly={!isRedact} value={params.box_scotch ? params.box_scotch : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Работа упаковщика</span>
                                        <input  onChange={e => setParams({...params, job_upakovka: e.target.value})} readOnly={!isRedact} value={params.job_upakovka ? params.job_upakovka : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Пупырка пакет</span>
                                        <input  onChange={e => setParams({...params, paket: e.target.value})} readOnly={!isRedact} value={params.paket ? params.paket : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Отливающий инструмент</span>
                                        <input  onChange={e => setParams({...params, instrum: e.target.value})} readOnly={!isRedact} value={params.instrum ? params.instrum : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Аренда</span>
                                        <input  onChange={e => setParams({...params, arenda: e.target.value})} readOnly={!isRedact} value={params.arenda ? params.arenda : ""} className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                    <div className={"box-bb-line"}>
                                        <span  className={"ff_name"}>Итого</span>
                                        <input value={params.arenda*1 + params.instrum*1 + params.paket*1 + params.job_upakovka*1 + params.box_scotch*1 + params.etiketka_box*1 + params.etiketka_flac*1}  readOnly={!isRedact}  className={"ff_input"} type="text" name="" id=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*///Наценка по остатку///*/}
                        <div className={ user.getUserRights && user.getUserRights.guide_nacenki_po_ostatky_view  ? "wrapper_for_settings" : "wrapper_for_settings display_none" }>

                            <div className={ user.getUserRights && user.getUserRights.guide_nacenki_po_ostatky_edit  ? "box_bb_container blc" : "box_bb_container blc pointer_events_none"}>
                                <span className={"titlebox"}>Наценка по остатку</span>
                                <div className={"box_table_bb horizont"}>
                                    <div className={"col_row"}>
                                        <span className={"obj"}> Обьем мл. </span>
                                        <span className={"obj"}> 1 </span>
                                        <span className={"obj"}> 2 </span>
                                        <span className={"obj"}> 3 </span>
                                        <span className={"obj"}>5 </span>
                                        <span className={"obj"}>10 </span>
                                        <span className={"obj"}>15 </span>
                                        <span className={"obj"}> Наценка </span>
                                    </div>
                                    {
                                        nacenkaNaOstatok ? nacenkaNaOstatok.map((item, index) =>
                                            <div className={"col_row"}>
                                                <span className={"obj"}> {"< "+item.value} </span>
                                                <span className={"obj"}> <div onClick={ e => checkboxChangeHandler(index,1)} className={ item[1] ? "checkbox_cc active" : "checkbox_cc" }></div>  </span>
                                                <span className={"obj"}> <div onClick={ e => checkboxChangeHandler(index,2)} className={ item[2] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                                <span className={"obj"}> <div onClick={ e => checkboxChangeHandler(index,3)} className={ item[3] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                                <span className={"obj"}><div onClick={ e => checkboxChangeHandler(index,5)} className={ item[5] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                                <span className={"obj"}><div onClick={ e => checkboxChangeHandler(index,10)} className={ item[10] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                                <span className={"obj"}><div onClick={ e => checkboxChangeHandler(index,15)} className={ item[15] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                                <input onChange={ e => nacenChangeHandler(index,e.target.value)} readOnly={!isRedact} value={ item.nacen ? item.nacen : ""} className={"obj"} />
                                            </div>
                                        ) : ''
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={ user.getUserRights && user.getUserRights.guide_nacenki_na_nabor_view  ? "wrapper_for_settings" : "wrapper_for_settings display_none" }>

                            <div className={ user.getUserRights && user.getUserRights.guide_nacenki_na_nabor_edit  ? "box_bb_container blc" : "box_bb_container blc pointer_events_none"}>
                                <span className={"askri_nac noml"}> Наценка на набор: </span>
                                <div className={"inft_prt"}>
                                    <span className={"jj_gvk"}>Расходник на набор</span>
                                    <input className={"glob_cof"} readOnly={!isRedact} value={params && params.nacenka_na_edenicy_flacona ? params.nacenka_na_edenicy_flacona : ""}  onChange={e => setParams({...params, nacenka_na_edenicy_flacona: e.target.value})} type="text"/>
                                </div>
                                <div className={"inft_prt"}>
                                    <span className={"jj_gvk"}>Наценка на расходники</span>
                                    <input className={"glob_cof"} readOnly={!isRedact} value={params && params.nacenka_na_rashodniki ? params.nacenka_na_rashodniki : ""}  onChange={e => setParams({...params, nacenka_na_rashodniki: e.target.value})} type="text"/>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className={ user.getUserRights && user.getUserRights.guide_itog_rashod_ot_flakona_view  ? "wrapper_for_settings" : "wrapper_for_settings display_none" }>

                        <div className={ user.getUserRights && user.getUserRights.guide_itgo_rashod_ot_flakona_edit  ? "two_line_exp " : "two_line_exp pointer_events_none"}>
                            <div className={"one_container_table_ll"}>
                                <span className={"strong big"}>Итоговый расход зависящий от объёма флакона</span>
                                <div className={"table_nac"}>
                                    <div className={"top_line_nac_table strong"}>
                                        <div className={"one_item_nac_param_v2 fzp_block"}>
                                            Объем
                                        </div>
                                        <div className={"one_item_nac_param_v2 fzp_block"}>
                                            Флакон+упаковка
                                        </div>
                                        <div className={"one_item_nac_param_v2 fzp_block"}>
                                            Труд отливальщика
                                        </div>
                                        <div className={"one_item_nac_param_v2 fzp_block"}>
                                            Итого расход
                                            без сырья
                                        </div>

                                    </div>

                                    {
                                        itogRashod ? itogRashod.map((item, index) =>
                                            <div className={"top_line_nac_table"}>

                                                <input readOnly={!isRedact} value={item.value ? item.value : ""} className={"one_item_nac_param_v2 fzp_block fz_correct"}/>
                                                <input readOnly={!isRedact} value={item.flak_upak ? item.flak_upak : ""} onChange={e => itogRashodChangeField(index,e.target.value,"flak_upak")} className={"one_item_nac_param_v2 fzp_block fz_correct"}/>
                                                <input readOnly={!isRedact} value={item.trud ? item.trud : ""} onChange={e => itogRashodChangeField(index,e.target.value,"trud")} className={"one_item_nac_param_v2 fzp_block fz_correct"}/>
                                                <input readOnly={!isRedact} value={params.arenda*1 + params.instrum*1 + params.paket*1 + params.job_upakovka*1 + params.box_scotch*1 + params.etiketka_box*1 + params.etiketka_flac*1 + item.trud*1 + item.flak_upak*1} className={"one_item_nac_param_v2 fzp_block fz_correct"}/>

                                            </div>
                                        ) : ''
                                    }


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"two_line_exp"}>


                        <div className={ user.getUserRights && user.getUserRights.guide_nacenka_for_site_price_view  ? "wrapper_for_settings" : "wrapper_for_settings display_none" }>

                            <div className={ user.getUserRights && user.getUserRights.guide_nacenka_for_site_price_edit  ?  "one_container_table_ll" : "one_container_table_ll pointer_events_none" }>
                                <span className={"strong big"}>Наценка для цены на сайте</span>
                                <div className={"table_nac"}>
                                    <div className={"top_line_nac_table strong"}>
                                        <div className={"one_item_nac_param"}>
                                            Оптовая
                                        </div>
                                        <div className={"one_item_nac_param"}>
                                            Наценка
                                        </div>
                                        <div className={"one_item_nac_param wd300"}>
                                            Корректировка в рублях
                                        </div>
                                        {/*<div className={"one_item_nac_param"}>*/}
                                        {/*    Цена на сайте*/}
                                        {/*</div>*/}
                                    </div>

                                    {
                                        nacenForSitePrice ? nacenForSitePrice.map((item, index) =>
                                            <div className={"top_line_nac_table"}>
                                                <div className={"one_item_nac_param"}>
                                                    {"< "+item.value}
                                                </div>
                                                <input readOnly={!isRedact} value={item.nacen ? item.nacen : ""} onChange={e => nacenTableChangeHandler(index,e.target.value)} className={"one_item_nac_param fz_correct"}/>
                                                <input readOnly={!isRedact} value={item.correct ? item.correct : ""} onChange={e => correctTableChangeHandler(index,e.target.value)} className={"one_item_nac_param wd300 fz_correct"}/>
                                                {/*<input readOnly={!isRedact} value={item.site_price ? item.site_price : ""} onChange={e => sitePriceTableChangeHandler(index,e.target.value)} className={"one_item_nac_param fz_correct"}/>*/}

                                            </div>
                                        ) : ''
                                    }


                                </div>
                            </div>
                        </div>


                        <div className={ user.getUserRights && user.getUserRights.guide_nacenka_opt_view  ? "wrapper_for_settings opt_ml" : "wrapper_for_settings display_none" }>

                            <div className={user.getUserRights && user.getUserRights.guide_nacenka_opt_edit  ? "one_container_table_ll" : "one_container_table_ll pointer_events_none"}>
                                <span className={"strong big"}>Наценка оптовая</span>
                                <div className={"table_nac"}>
                                    <div className={"top_line_nac_table strong"}>
                                        <div className={"one_item_nac_param_v2 fp_block"}>
                                            Цена за мл,₽
                                        </div>
                                        <div className={"one_item_nac_param_v2"}>
                                            1 мл
                                        </div>
                                        <div className={"one_item_nac_param_v2"}>
                                            2 мл
                                        </div>
                                        <div className={"one_item_nac_param_v2"}>
                                            3 мл
                                        </div>
                                        <div className={"one_item_nac_param_v2"}>
                                            5 мл
                                        </div>
                                        <div className={"one_item_nac_param_v2"}>
                                            10 мл
                                        </div>
                                        <div className={"one_item_nac_param_v2"}>
                                            15 мл
                                        </div>
                                    </div>

                                    {
                                        optNacen ? optNacen.map((item, index) =>
                                            <div className={"top_line_nac_table"}>

                                                <input readOnly={!isRedact} value={item.stop ? item.start +" - "+ item.stop : ""} className={"one_item_nac_param_v2 fp_block fz_correct"}/>
                                                <input readOnly={!isRedact} value={item.ml_1 ? item.ml_1 : ""} onChange={e => optTableChangeField(index,e.target.value,"ml_1")} className={"one_item_nac_param_v2  fz_correct"}/>
                                                <input readOnly={!isRedact} value={item.ml_2 ? item.ml_2 : ""} onChange={e => optTableChangeField(index,e.target.value,"ml_2")} className={"one_item_nac_param_v2  fz_correct"}/>
                                                <input readOnly={!isRedact} value={item.ml_3 ? item.ml_3 : ""} onChange={e => optTableChangeField(index,e.target.value,"ml_3")} className={"one_item_nac_param_v2  fz_correct"}/>
                                                <input readOnly={!isRedact} value={item.ml_5 ? item.ml_5 : ""} onChange={e => optTableChangeField(index,e.target.value,"ml_5")} className={"one_item_nac_param_v2  fz_correct"}/>
                                                <input readOnly={!isRedact} value={item.ml_10 ? item.ml_10 : ""} onChange={e => optTableChangeField(index,e.target.value,"ml_10")} className={"one_item_nac_param_v2  fz_correct"}/>
                                                <input readOnly={!isRedact} value={item.ml_15 ? item.ml_15 : ""} onChange={e => optTableChangeField(index,e.target.value,"ml_15")} className={"one_item_nac_param_v2  fz_correct"}/>

                                            </div>
                                        ) : ''
                                    }


                                </div>
                            </div>
                            <div className={"box_bb_container two_blc"}>
                                <span className={"titlebox"}>Автоматическое отключение сетов</span>
                                <div className={"box_table_bb horizont"}>
                                    <div className={"col_row"}>
                                        <span className={"obj"}> Обьем мл. </span>
                                        <span className={"obj"}> 1 </span>
                                        <span className={"obj"}> 2 </span>
                                        <span className={"obj"}> 3 </span>
                                        <span className={"obj"}>5 </span>
                                        <span className={"obj"}>10 </span>
                                        <span className={"obj"}>15 </span>
                                    </div>
                                    {
                                        autoChangeActiveSet ? autoChangeActiveSet.map((item, index) =>
                                            <div className={"col_row"}>
                                                <span className={"obj"}> {"< "+item.value} </span>
                                                <span className={"obj"}> <div onClick={ e => checkboxChangeHandlerSet(index,1)} className={ item[1] ? "checkbox_cc active" : "checkbox_cc" }></div>  </span>
                                                <span className={"obj"}> <div onClick={ e => checkboxChangeHandlerSet(index,2)} className={ item[2] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                                <span className={"obj"}> <div onClick={ e => checkboxChangeHandlerSet(index,3)} className={ item[3] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                                <span className={"obj"}><div onClick={ e => checkboxChangeHandlerSet(index,5)} className={ item[5] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                                <span className={"obj"}><div onClick={ e => checkboxChangeHandlerSet(index,10)} className={ item[10] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                                <span className={"obj"}><div onClick={ e => checkboxChangeHandlerSet(index,15)} className={ item[15] ? "checkbox_cc active" : "checkbox_cc" }></div> </span>
                                            </div>
                                        ) : ''
                                    }
                                </div>
                            </div>
                        </div>



                    </div>

                    <div className={"two_line_exp mb_tt"}>
                <span onClick={e => saveTable()} className={"heading_btn_add_user noml"}>
                    Сохранить
                </span>
                    </div>



                </div>
            );



});

export default AllParamsPage;

