import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import UserStore from "./store/UserStore";
import SettingsStore from "./store/SettingsStore";

//
import  useEffect from 'react';


export const Context = createContext(null);

ReactDOM.render(
    <Context.Provider value={{
        user: new UserStore(),
        settings: new SettingsStore(),
        // basket: new BasketStore(),
    }}>

        <App  />
    </Context.Provider>,

    document.getElementById('root')
);

