import React, {useContext, useEffect, useState} from 'react';
import './HistoryUse.css';
import {observer} from "mobx-react-lite";
import {useLocation} from "react-router";
import {useParams} from "react-router-dom";
import {createBuys, fetchAllBuys} from "../../http/AromaApi";
import {fetchAllBrand, fetchDeactiveBrand} from "../../http/BrandApi";
import Pagination from "../UI/Paginstion";
import {Context} from "../../index";

const HistoryUse = observer((props) => {

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')

    const {settings,user} = useContext(Context);
    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const [buysArray,setBuysArray] = useState(null)
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const [refresh,setRefresh] = useState(false);

    let idAroma = params.id;

    useEffect(() => {
        fetchAllBuys(limit,(page-1)*limit,true,searchRoleParam,searchQuery,idAroma).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setCount(response.count)
            setBuysArray(response.rows);

            console.log(response)
            console.log("BUYS")

        })


    }, [page,searchRoleParam,searchQuery, refresh])


    // useEffect(() => {
    //     createBuys(123456,"12.02.2024","Chanel Parfum","123456","20","5","1200",idAroma).then(response => {
    //         console.log(response)
    //     })
    // },[])

    if(user.getUserRights && user.getUserRights.view_history_buys ) {
        return (
            <div className={props.active ? "history_use_area active" : "history_use_area"}>
                <div className={"table_history_use"}>
                    <div className={"top_line_history_use"}>
                        <div className={"n_order"}>Номер заказа</div>
                        <div className={"d_order"}>Дата заказа</div>
                        <div className={"op_order"}>Состав заказа</div>

                    </div>
                    {
                        buysArray ? buysArray.map((item, index) =>
                            <div className={"line_order_box"}>
                                <div className={'box_n_order'}>
                                    <a target={"_blank"} href={`https://allureparfum.retailcrm.ru/orders/${item.idForOrderRetailUrl}/edit`}>{item.orderId}</a>
                                    <div className={item.type == "refound" ? "item_status red" : "item_status"}>
                                        {
                                            item.type == "refound" ? "ВОЗВРАТ" : "ПОКУПКА"
                                        }
                                    </div>
                                </div>
                                <div className={"box_d_order"}>{item.date}</div>
                                <div className={"container_op"}>
                                    <div className={"box_for_op"}><span>Название товара:</span> <span className={"param_order_data"}>{item.nameAroma}</span></div>
                                    <div className={"box_for_op"}><span>Артикул:</span> <span className={"param_order_data"}>{item.articul}</span></div>
                                    <div className={"box_for_op"}><span>Объем:</span> <span className={"param_order_data"}>{item.volume}</span></div>
                                    <div className={"box_for_op"}><span>Количество:</span> <span className={"param_order_data"}>{item.count}</span></div>
                                    {/*<div className={"box_for_op"}><span>Цена для сайта:</span> <span className={"param_order_data"}>{item.price_for_site}</span></div>*/}
                                </div>
                            </div>
                        ) : ''
                    }
                </div>

                {
                    props.activePage == "history-use" &&
                    <Pagination page={page} limit={limit} count={count}/>
                }

            </div>
        );
    } else {
        return (

            <div className={props.active ? "history_use_area active" : "history_use_area"}>
                <div className={"close_page_for_you"}>
                    <span>Доступ закрыт</span>
                </div>
            </div>

        )
    }


});

export default HistoryUse;

