import React, {useContext, useEffect, useState} from 'react';
import "./addAromaPage.css";
import {observer} from "mobx-react-lite";
import {fetchAllBrand} from "../../http/BrandApi";
import {fetchAllMl} from "../../http/MlApi";
import {logDOM} from "@testing-library/react";
import {useLocation, useNavigate} from "react-router";
import {Link, Navigate, useParams} from "react-router-dom";
import {fetchAllPost} from "../../http/PostavAPI";
import {converter_RUB_S, converter_S_RUB, mlData, priceToUserString} from "../../http/SupportFunctions";
import Logotype from "../../components/header/MainHeaderLine/Logotype/Logotype";
import {createAromaAndPost} from "../../http/AromaApi";
import {Context} from "../../index";
import {keyboard} from "@testing-library/user-event/dist/keyboard";
const AddAromaPage =  observer(() => {
    const {settings,user} = useContext(Context);

    const Navigation = useNavigate()

    const [brands, setBrands] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [openAreaBrand, setOpenAreaBrand] = useState(false);
    const [aromaName,setAromaName] = useState("")
    const [MillilitresSelect, setMillilitresSelect] = useState([]);
    const [postSelect, setPostSelect] = useState(null);
    const [sostavshikArray, setPostavshikArray] = useState(null);
    const [postavSearchQuery, setPostavSearchQuery] = useState('')
    const [openPostavSelect, setOpenPostavSelect] = useState(false);
    const [messageBackend, setMessageBackend] = useState('');
    const [typeMessage, setTypeMessage] = useState(false);

    //##

    const [volumeMl, setVolumeMl] = useState(0);
    const [count, setCount] = useState(1);
    const [price_S, setPrice_S] = useState(null);
    const [price_R, setPrice_R] = useState(null);
    const [priceMl_S, setPriceMl_S] = useState(null);
    const [priceMl_R, setPriceMl_R] = useState(null)
    const [comment, setComment] = useState("");

    const [allTriger,setAllTriger] = useState(false);

    const [deactiveButton, setDeactiveButton] = useState(false);

    // brand, name, ml, volume, count, price_S, price_R, postavshik, price_S_ml, price_R_ml, comment
    const createhandler = () => {
        setDeactiveButton(true)
        if(deactiveButton) {
            return false;
        }
        createAromaAndPost(
            selectedBrand,aromaName,MillilitresSelect,volumeMl, count, price_S, price_R, postSelect, priceMl_S,priceMl_R,comment,user
            ).then(response => {
            if(response.error) {
                setDeactiveButton(false)
                setMessageBackend(response.error);
                setTypeMessage(false)
            }
            if(response.message) {
                setDeactiveButton(false)
                setMessageBackend(response.message);
                setTypeMessage(true);
                Navigation("/adm/aroma/"+response.newAroma.id)
            }
        })
    }



    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState("");
    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')
    const [refresh,setRefresh] = useState(false);









    useEffect(() => {

        fetchAllBrand(999,0,true,searchRoleParam,searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setBrands(response.rows);

        })
    },[page,searchQuery,refresh])

    useEffect(() => {
        fetchAllPost(999,0,true,searchRoleParam,postavSearchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setPostavshikArray(response.rows);
        })
    },[page,postavSearchQuery])


    const [ruTriger,setRuTriger] = useState(false);
    const [sTruger,setSTriger] = useState(false)


    useEffect( () => {
        console.log(price_S)
        let priceR = converter_S_RUB(price_S, settings.getRubCurse)
        let priceRml = converter_S_RUB(priceMl_S, settings.getRubCurse)

        let mlPrice_s = price_S/volumeMl;
        let mlPrice_r = converter_S_RUB(mlPrice_s,settings.getRubCurse);
        // за флакон
        setPrice_R(priceR)
        setPriceMl_R(priceRml)
        // в мл
        setPriceMl_S(mlPrice_s.toFixed(2));
        setPriceMl_R(mlPrice_r);
    },[sTruger,allTriger])

    useEffect( () => {
        console.log(price_S)
        let priceS = converter_RUB_S(price_R, settings.getRubCurse)
        let priceSml = ((priceS/volumeMl)*1).toFixed(2);
        let mlPrice_RU = (price_R/volumeMl).toFixed(2);;
        // let mlPrice_r = converter_RUB_S(mlPrice_st,CURSE);
        // priceSml = (priceSml*1).toFixed(2);
        //
        // за флакон
        setPrice_S(priceS)
        setPriceMl_S(priceSml)
        // в мл
        // setPriceMl_S(mlPrice_st);
        setPriceMl_R(mlPrice_RU);
    },[ruTriger,allTriger])

    const selectBrandHandler = (item) => {
        setSelectedBrand(item)
        setOpenAreaBrand(false);
    }
    const selectPostHandler = (item) => {
        setPostSelect(item)
        setOpenPostavSelect(false);
    }

    const searchSupportFucntion = (value) => {
        if(!MillilitresSelect.length) {
            return false;
        }
        let mlId = MillilitresSelect.find(ml => ml.value === value);
        if(!mlId) {
            return false;
        }

        return true;
    }

    const selectMlHandler = (item) => {

        let newArray = [];
        // if(!MillilitresSelect.length) {
        //     newArray.push(item);
        //     setMillilitresSelect(newArray)
        //     return false;
        // }

        if(mlData) {
            mlData.forEach(ml => {
                if(ml.value <= item.value) {
                    newArray.push(ml);
                }
            })
            setMillilitresSelect(newArray)
        }


        // let searchResult = MillilitresSelect.find(ml => ml.value === item.value);
        //
        // if(!searchResult) {
        //     newArray = [...MillilitresSelect];
        //     newArray.push(item);
        //     setMillilitresSelect(newArray)
        //     return false;
        // }
        //
        // if(searchResult) {
        //     newArray = [...MillilitresSelect];
        //     for (var i = newArray.length - 1; i >= 0; --i) {
        //         if (newArray[i].value == item.value) {
        //             newArray.splice(i,1);
        //         }
        //     }
        //     setMillilitresSelect(newArray)
        // }

    }
    const changeVolumeHandler = (value) => {

        let modifyValue = value.replace(/\D/g, '')

        setVolumeMl(modifyValue)
        setAllTriger(!allTriger)
    }


    if(user.getUserRights && user.getUserRights.add_aroma) {
        return (
            <div className={"pages_wrapper_stand"}>
                <div className={"top_line_controll"}>
                    <span className={"name_page_span"}>Добавить аромат</span>
                </div>
                <div className={"content_area_main"}>
                    <div className={"top_line_inputs_wrapper"}>
                        <div className={"classic_wrap_fields"}>
                            <span className={"name_brand_alt"}>Бренд</span>
                            <div className={'selected_menu_container'}>
                                <span onClick={e => setOpenAreaBrand(!openAreaBrand)} className={ openAreaBrand ? "brand_field active" : "brand_field" }>{ selectedBrand ? selectedBrand.name : "Выберите бренд"  }</span>
                                <div className={ openAreaBrand ? "menu_hidden_area open" : "menu_hidden_area"  }>

                                    <div className={"aroma_wrapp_for_search"}>
                                        <div className={'seach_container'}>
                                            <input
                                                className={"search_input"}
                                                value={searchQuery ? searchQuery : ""}
                                                onChange={ e => setSearchQuery(e.target.value)}
                                                placeholder={"Поиск..."} type="text"/>
                                            <div className={"abs_lupa"}>
                                                <img src="/lupa.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"scroll_container_aroma"}>
                                        {
                                            brands ? brands.map((item, index) =>
                                                {
                                                    if(item.isActive) {
                                                        return(
                                                            <div onClick={e => selectBrandHandler(item)} className={"wrapper_for_select_brand"} key={index}>
                                                                <span  className={"one_brand_item"}>{item.name}</span>
                                                            </div>
                                                        )
                                                    }
                                                }

                                            ) : ''
                                        }
                                    </div>
                                    <Link className={"redirect_button"} to="/adm/brand" target="_blank" rel="noopener noreferrer">Добавить бренд</Link>

                                </div>
                            </div>
                        </div>
                        <div className={"classic_wrap_fields name_field_custom"}>
                            <span className={"name_brand_alt"}>Аромат</span>
                            <input className={"name_aroma_input"} onChange={e => setAromaName(e.target.value)} value={aromaName ? aromaName : ""} type="text"/>
                        </div>


                    </div>
                    <div className={"wrapper_for_entrance_add"}>
                        <span className={"entrance_title_block"}>Добавить поступление</span>
                        <div className={"entrance_container_container"}>
                            <div className="entrance_field_container">
                                <div className={"name_field_entrance_container"}>Поставщик</div>
                                <div   className={ openPostavSelect ? "entrance_selector_container active" : "entrance_selector_container" }>
                                    <span onClick={e => setOpenPostavSelect(!openPostavSelect)}> { postSelect ? postSelect.name : "Выберите поставщика"  }</span>
                                    <div className={"menu_hidden_area"}>

                                        <div className={"aroma_wrapp_for_search"}>
                                            <div className={'seach_container'}>
                                                <input
                                                    className={"search_input"}
                                                    value={postavSearchQuery ? postavSearchQuery : ""}
                                                    onChange={ e => setPostavSearchQuery(e.target.value)}
                                                    placeholder={"Поиск..."} type="text"/>
                                                <div className={"abs_lupa"}>
                                                    <img src="/lupa.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"scroll_container_aroma"}>
                                            {
                                                sostavshikArray ? sostavshikArray.map((item, index) =>
                                                    {
                                                        if(item.isActive) {
                                                            return(
                                                                <div onClick={e => selectPostHandler(item)} className={"wrapper_for_select_brand"} key={index}>
                                                                    <span  className={"one_brand_item"}>{item.name}</span>
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                ) : ''
                                            }
                                        </div>
                                        <Link className={"redirect_button"} to="/adm/post" target="_blank" rel="noopener noreferrer">Добавить поставщика</Link>
                                    </div>
                                </div>
                            </div>
                          <div className={"entrance_field_container"}>
                              <div className="entrance_field_container">
                                  <div className={"name_field_entrance_container"}>Объем, мл</div>
                                  <input onChange={e => changeVolumeHandler(e.target.value)} value={ volumeMl ? volumeMl : "" } className={"two_entrance_input"} type="text"/>
                              </div>
                              <div className="entrance_field_container">
                                  <div className={"name_field_entrance_container"}>Кол-во</div>
                                  <input onChange={e => setCount(e.target.value)} value={ count ? count : "" } className={"two_entrance_input"} type="text"/>
                              </div>
                          </div>
                            <div className="entrance_field_container">
                                <div className={"name_field_entrance_container purple"}>Цена $</div>
                                <input onChange={e => [setPrice_S(e.target.value),setSTriger(!sTruger)]} value={price_S ? price_S : ""} className={"two_entrance_input"} type="text"/>
                                <div className={"name_field_entrance_container orange"}>Цена ₽</div>
                                <input onChange={e => [setPrice_R(e.target.value),setRuTriger(!ruTriger)]} value={price_R ? price_R : ""} className={"two_entrance_input"} type="text"/>
                            </div>





                            <div className="entrance_field_container">
                                <div className={"name_field_entrance_container"}>Цена за мл, $</div>
                                <input value={priceMl_S ? priceMl_S : ""} className={"two_entrance_input"} type="text"/>
                                <div className={"name_field_entrance_container"}>Цена за мл, ₽</div>
                                <input value={priceMl_R ? priceMl_R : ""} className={"two_entrance_input"} type="text"/>
                            </div>
                            <div className="entrance_field_container">
                                <div className={"name_field_entrance_container"}>Примечание</div>
                                <input onChange={e => setComment(e.target.value)} value={comment ? comment : ""}   className={"big_entrance_input_container"} type="text"/>
                            </div>
                        </div>
                    </div>
                    <div className={"classic_wrap_fields name_field_custom fix_trans"}>
                        <span className={"Доступные варианты"}>Выберите доступные варианты обьема</span>
                        <div className={"checkbox_container_custom"}>
                            {
                                mlData ? mlData.map((item, index) =>
                                    <div  onClick={e => selectMlHandler(item)} key={index} className={ searchSupportFucntion(item.value) ? "ml_wrapper active" : "ml_wrapper"}>
                                        <div className={"box-check"}></div>
                                        <span>{item.value + " мл"}</span>
                                    </div>
                                ) : ""
                            }
                        </div>
                    </div>
                    <div className={"save_aroma_line"}>
                        <span className={typeMessage ? "green message" : "red message"}>{messageBackend}</span>   <span onClick={e => createhandler()} className={deactiveButton ? "save_aroma_btn deactive_button" : "save_aroma_btn"}>Сохранить</span>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={"close_page_for_you"}>
                <span>Доступ закрыт</span>
            </div>
        )
    }

});



export default AddAromaPage;
