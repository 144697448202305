import React, {useContext, useEffect, useState} from 'react';
import "./detailAreaAroma.css";
import {
    addImages,
    changeActiveEntrance, createFolder,
    createNewChillProducts,
    getAromaAndEntrance,
    getLastEntranceAroma,
    getLastEntranceAromaByAromaId,
    getRashod,
    getSetsByAromaId,
    saveNewLink,
    saveRemain,
    saveTableOptions,
    setSelectedImg,
    updateBrandByAromaId,
    updateOstatokById
} from "../../http/AromaApi";
import {useLocation} from "react-router";
import {Link, useParams} from "react-router-dom";
import {getHumanDate, mlData, priceToUserString} from "../../http/SupportFunctions";
import Pagination from "../UI/Paginstion";
import SuccessModal from "../UI/SuccessModal/SuccessModal";
import AnswerModalDeactive from "../UI/AnswerModalDelete/AnswerModalDeactive";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {fetchAllBrand} from "../../http/BrandApi";
import {logDOM} from "@testing-library/react";

const DetailAreaAroma = observer((props) => {
    const {settings,user} = useContext(Context);

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page');

    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }
    let id = params.id;

    const [entrance,setEntrance] = useState(null);


    const [opAnswModal,setOpAnswModal] = useState(false);
    const [textAnswModal, setTextAnswModal] = useState('');
    const [btnText, setBtnText] = useState('');
    const [idAnswModal, setIdAnswModal] = useState('');
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');
    const [refresh, setRefresh] = useState(false);

    const [сonsumptionPerMonth, setConsumptionPerMonth] = useState(null);
    const [setsForAroma, setSetsForAroma] = useState(null);

    const [aroma,setAroma] = useState(null)
    const [reason,setReason] = useState(null);
    const [reasonErrorText, setReasonErrorText] = useState(null);

    const [childProducts,setChildProducts] = useState(null);

    const [newLink,setNewLink] = useState(null);
    const [changeRemainField,setChangeRemainField] = useState(false);
    const [mlForSelected,setMlForselected] = useState(null);

    const [countHistory,setCountHistory] = useState(0);

    useEffect(() => {
        let array = [...mlData];
        const newFirstElement = {value:""}
        const newArray = [newFirstElement].concat(array)

        setMlForselected(newArray);

        getAromaAndEntrance(id).then(response => {
            if(response.error) {
                return false;
            }
            setAroma(response);
            setSelectedStopper(response.auto_ml_change)
            console.log("AROMA")
            console.log(response)
            let ml = response.items_ml;
            console.log("MLL");
            setNewLink(response.link);
            console.log(ml)
            ml.sort(function(a, b) {
                return parseFloat(a.value) - parseFloat(b.value);
            });

            setChildProducts(ml);
            console.log("Весь аромат со вложенностями")
            console.log(response)

        })

        getLastEntranceAromaByAromaId(id).then(response => {
            console.log(response)
            console.log('66666')
            setEntrance(response);
        })

        getRashod(id).then(response => {

            setConsumptionPerMonth(response.data)

        })


        getSetsByAromaId(id).then(response => {

            if(response.data){
                let newArray = [];

                response.data.items_set_table.forEach(item => {
                    let wrong = false;
                    item.items_set_table.forEach(oneAroma => {
                        if(oneAroma.ostatok <= 30) {
                            wrong =  true;
                        }
                    })
                    let updateItem = {...item, wrong:wrong};
                    newArray.push(updateItem)
                })
                setSetsForAroma(newArray);


            }


        })

    if(props.history) {
        setCountHistory(props.history.length)
    }


    }, [refresh, id, props])

    const openAnswModalDeactive = (item) => {
        let text = item.isActive ? "Деактивировать" : "Активировать";
        setBtnText(text)
        setTextAnswModal(`Вы точно хотите ${text} данную поставку?`);
        setIdAnswModal(item.id)
        setOpAnswModal(true)
        user.setShadowOverlay(true);
    }
    const closeAnswModal = () => {
        setOpAnswModal(false);
        user.setShadowOverlay(false);
    }


    const saveRemainField = async () => {
        const response = await saveRemain(aroma.id,aroma.remainsVerificator);
        if(response.success){
            setChangeRemainField(false)
        }
    }



    const [openSaveBtn, setOpenSaveBtn] = useState(false)
    const changeOstatokHandler = (value) => {
        setAroma({...aroma, ostatok:value})
        setOpenSaveBtn(true);
    }




    const updateItemOstatok = (item) => {

        if(!reason || reason.length < 3) {
            setReasonErrorText("Укажите причину не менее 3-х символов")
            return false;
        }

        updateOstatokById(item,user.user,reason).then(response => {
            if(response.message) {
                setOpenSaveBtn(false);
                user.setShadowOverlay(true)
                setOpenSuccessModal(true);
                setSuccessText("Остаток сохранен успешно")
                setReasonErrorText("");
                setCountHistory(countHistory+1)
            }
        })
    }

    const [brands,setBrands] = useState(null);

    const searchRoleParam = "";
    const [brandsArr,setBrandsArr] = useState(null);

    const [messageSaveLink,setMessageSaveLink] = useState(null)


    const [messageEditButton,setMessageEditButton] = useState("Добавить")
    const [openEditMenu,setOpenEditMenu] = useState(false);
    const [selectedItems,setSelectedItems] = useState(null);

    const openEditAndSaveHandler = async () => {
            if(openEditMenu == false) {
                setOpenEditMenu(true);
                setMessageEditButton("Сохранить")

                let newArraySelected = [];
                mlData.forEach((oneMl) => {
                    let findItem = childProducts.find(ml => ml.value === oneMl.value);
                    if(findItem) {
                        newArraySelected.push({value:oneMl.value, check:true})
                    } else {
                        newArraySelected.push({value:oneMl.value, check:false})
                    }

                })

                setSelectedItems(newArraySelected);
                console.log(newArraySelected);

            } else {
                setOpenEditMenu(false);
                setMessageEditButton("Добавить")
                let response = await createNewChillProducts(aroma,selectedItems);
                console.log(response)
            }
    }
    const changeActiveEntranceHandler = async () => {
        let response = await changeActiveEntrance(idAnswModal);
        // console.log(response)
        if(response.message) {
            setOpAnswModal(false);
            user.setShadowOverlay(false);
            setRefresh(!refresh);
        }
    }

    useEffect(() => {
        if(user.shadowOverlay == false) {
            // setOpenAddPostModal(false);
            setOpenSuccessModal(false);
            setOpAnswModal(false)
            setOpenSuccessModal(false);
            setOpenModalUploadFile(false)
        }
    },[user.shadowOverlay])



    const switchCheckboxHandler = (index) => {
        let newArray = [...childProducts];
        newArray[index].items_ml_table.isActive = !newArray[index].items_ml_table.isActive;
        setChildProducts(newArray)
    };

    const switchArticulHandler = (index,value) => {
        let newArray = [...childProducts];
        newArray[index].items_ml_table.articul = value;
        setChildProducts(newArray)
    }

    const changeHandlTableSelector = (value) => {
        let newArray = {...aroma, handChangeTable:value};
        setAroma(newArray)
    }

    const [openMenuSelector,setOpenMenuSelector] = useState(false);
    const [selectedStopper,setSelectedStopper] = useState("");


    const selectedStopperHandler = (value) => {
        setSelectedStopper(value)
        setOpenMenuSelector(false);
    }


    const [successMessage,setSuccessMessage] = useState("");
    const saveTableOptionsHandler = () => {
        saveTableOptions(aroma,childProducts,selectedStopper).then(response => {
            if(response.success) {
                setSuccessMessage(response.success)
                setRefresh(!refresh)
            }
        })
    }


    const saveLinkItem = (id,value) => {
        saveNewLink(id,value).then(response => {
            setMessageSaveLink(response.success);
        })
    }

    const switchEditHandler = (index) => {
            let newArray = [...selectedItems];
            newArray[index].check = !newArray[index].check;
            setSelectedItems(newArray);
    }

    const [openModalUploadFile, setOpenModalUploadFile] = useState(false);

    const [imagePath,setImagePath] = useState(null)

    const changeUploadOpenHandler = (value) => {
        setOpenModalUploadFile(value)
        user.setShadowOverlay(true)
    }
    const uploadImgAbout = (e,fieldName, index) => {

        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("file", newFile);

        addImages(formData,id).then(response => {
            if(response) {
                console.log('ssss')
                console.log(response)

                setImagePath("/uploads/images/"+id+"/"+response.fileName)
                console.log("/uploads/images/"+id+"/"+response.fileName)
            }
        })

    }

    const saveUploadImageToItem = async () => {
        let response =  await  setSelectedImg(id,imagePath);
        setOpenModalUploadFile(false);
        user.setShadowOverlay(false)
        setImagePath(null)
        setRefresh(!refresh)

    }



    return (
        <div className={props.active ? "detail_area_aroma_parrent_container" : "detail_area_aroma_parrent_container deactive"}>

            <SuccessModal isOpen={openSuccessModal} text={successText} />
            <AnswerModalDeactive isOpen={opAnswModal} text={textAnswModal} buttonText={btnText} function={changeActiveEntranceHandler} id={idAnswModal} close={closeAnswModal}  />
            {

                <div className={openModalUploadFile ? "add_user_modal active" : "add_user_modal"}>
                    <span className={"upload_modal_title"}>
                        Выберите файл, чтобы загрузить на сервер
                    </span>
                    <div className={"file_upload_button"} >
                        <span className={"abs_span_trx"}>Выбрать файл</span>
                        <input  onChange={ e => uploadImgAbout(e, 'imgUpper')} className={""} type="file" />
                    </div>
                    <div>
                        {
                            imagePath &&
                            <div className={"img_notificator"}>
                                <span className={"notificator_title"}>Изображение успешно загружено</span>
                                <span>Путь: {imagePath}</span>
                                <span
                                    onClick={ e => saveUploadImageToItem() }
                                    className={"select_upload_image"}>
                                    Установить изображение
                                </span>
                            </div>
                        }
                    </div>


                </div>
            }

            <div className={"epx_wrapper_container"}>
                <div className={"left_area_image"}>
                    <img src={aroma && aroma.imagePath ? aroma.imagePath : "/imgdefault.png"} alt=""/>
                    <span onClick={e => changeUploadOpenHandler(true)} className={'alure_link_button'}>
                        Загрузить новое фото
                    </span>

                    <Link target="_blank" to={aroma && aroma.link ? aroma.link : "/"} className={"alure_link_button"}>
                    АллюрПарфюм
                </Link>
                    <input onChange={ e => setNewLink(e.target.value)} value={newLink ? newLink : ""} placeholder={"Введите новую ссылку"} className={"link_change_button"} type="text"/>
                    <span onClick={e => saveLinkItem(aroma.id, newLink)} className={"alure_link_button"}>Сохранить ссылку</span>
                    <span className={"message_save_link"}>{messageSaveLink ? messageSaveLink : ""}</span>
                </div>
                <div className={"container_for_content_aroma_area"}>
                    <span className={"entrance_title_span"}> Последние пополнения </span>
                    <div className="table">
                        <div className={"fix_container_v4"}>
                            <div className="table_top_line_names">
                                <div className="date_tt table_names">Дата</div>
                                <div className="volume_tt table_names">Объем, мл</div>
                                <div className="count_items_tt table_names">Кол-во флаконов</div>
                                <div className="price_s_tt table_names">Цена $ </div>
                                <div className="price_r_tt table_names">Цена ₽</div>
                                <div className="price_inf_tt table_names">Изменение цены,%</div>
                                <div className="price_ml_s_tt table_names">Цена за мл $ </div>
                                <div className="price_ml_r_tt table_names">Цена за мл ₽</div>
                                <div className="ost_price_ml_tt table_names">Цена остатка за мл ₽</div>
                                <div className="ost_price_ml_tt table_names">Цена остатка за мл $</div>
                                <div className="post_name_tt table_names">Поставщик</div>
                                <div className="user_name_tt table_names">Имя сотрудника</div>
                                <div className="active_tt table_names">Деактивация</div>
                                <div className="comment_tt table_names">Примечание</div>
                            </div>

                            {
                                entrance ? entrance.map((item, index) =>
                                    <div className={"heading_line_aroma"} key={index}>
                                        <div className="date_tt generated_line">{getHumanDate(item.createdAt)}</div>
                                        <div className="volume_tt generated_line">{item.volume ? item.volume : ""}</div>
                                        <div className="count_items_tt generated_line">{item.cout ? item.cout : ""}</div>
                                        <div className="price_s_tt generated_line">{item.price_S ? item.price_S : ""}</div>
                                        <div className="price_r_tt generated_line">{item.price_S ? priceToUserString(item.price_S*settings.getRubCurse) : ""}</div>
                                        <div className={ item.procent_change_price < 0 ? "price_inf_tt generated_line blue" : "price_inf_tt generated_line red"}>{item.procent_change_price ? item.procent_change_price+"%" : ""}</div>
                                        <div className="price_ml_s_tt generated_line">{item.ml_price_S ? (item.ml_price_S*1).toFixed(2) : ""}</div>
                                        <div className="price_ml_r_tt generated_line">{item.ml_price_S ? priceToUserString(item.ml_price_S*settings.getRubCurse) : ""}</div>
                                        <div className="ost_price_ml_tt generated_line">{item.ost_price ? priceToUserString(item.ost_price*settings.getRubCurse) : ""}</div>
                                        <div className="ost_price_ml_tt generated_line">{item.ost_price ? priceToUserString(item.ost_price) : ""}</div>
                                        <div className="post_name_tt generated_line">{item.postavshik.name ? item.postavshik.name : ""}</div>
                                        <div className="user_name_tt generated_line">{item.nameUser ? item.nameUser : ""}</div>
                                        <div className="active_tt generated_line">
                                            <div onClick={e => openAnswModalDeactive(item)} className={ item.isActive ? "circle_container" : "circle_container deactive"}>

                                            </div>
                                        </div>
                                        <div className="comment_tt generated_line">
                                            {
                                                item.comment ?
                                                    <div className={"comment_area_nb"}>
                                                        <img src="/comment.png" alt=""/>
                                                        <div className={"comment_aft"}>
                                                            <div id="triangle"></div>
                                                            {item.comment}
                                                        </div>
                                                    </div>
                                                    : "-"
                                            }
                                        </div>
                                    </div>
                                ) : ''
                            }
                        </div>


                    </div>
                    <div className={"ml_wrapper_calculate"}>
                        <div className={"calculate_wrap_container"}>
                            <div className={"calculate_support_wrapper"}>
                                <div className="top_line_calc">
                                    <div className={"all_dv dv1"}>Остаток, мл</div>
                                    <div className={"all_dv dv2"}>Расход в месяц,мл</div>
                                    <div className={"all_dv dv3"}>Запас на 2 мес, мл</div>
                                    <div className={"all_dv dv4"}>Запас на 3 мес, мл</div>
                                </div>
                                <div className={"bottom_calculate_line"}>
                                    <input onChange={e => changeOstatokHandler(e.target.value)} value={aroma ?  aroma.ostatok : 0} className={ user.getUserRights && user.getUserRights.edit_item_ostatok ? "all_dv dv1" : "all_dv dv1 pnt_none"}/>

                                    <div className={"all_dv dv2"}>{сonsumptionPerMonth ? сonsumptionPerMonth : ""}</div>
                                    <div className={"all_dv dv3"}>{сonsumptionPerMonth*2}</div>
                                    <div className={"all_dv dv4"}>{сonsumptionPerMonth*3}</div>
                                </div>
                            </div>




                        </div>



                        <div className={ openSaveBtn ? "special_wrapp_container" : "special_wrapp_container close" }>
                            <div onClick={e => updateItemOstatok(aroma)} className={"save_btn_ost custom_tab_ost active"}>
                                Сохранить остаток
                            </div>
                            <input
                                className={"field_change_resson"}
                                placeholder={"Введите причину изменения"}
                                type="text"
                                value={reason ? reason : ""}
                                onChange={ e => setReason(e.target.value)}
                            />
                            <div className={"error_text_reason"}>{reasonErrorText ? reasonErrorText : "" }</div>
                        </div>

                    </div>

                    {
                        props.history && props.history.length && aroma ?
                            <Link className={"history_row"} to={`/adm/aroma/${aroma.id}/history`}>Посмотреть историю изменений {countHistory} шт</Link>
                            :
                            <div className={"history_row gray"}>
                                Нет истории изменений
                            </div>
                    }

                    <div className={"sets_by_aroma"}>
                        <div className={"dieskc"}>
                            <span className={"duesz"}>Есть в наборе</span> <span className={"urrrks"}>{setsForAroma ? setsForAroma.length+" шт" : "0 шт"}</span>
                        </div>
                    </div>
                    {
                        aroma &&
                        <div className={"parrent_container_for_remain_field"}>
                            <div className={"remain_wrapper_field"}>
                                    <span className={"remain_title_field"}>
                                        Сообщать при остатке меньше
                                    </span>
                                <input
                                    className={"remains_verificator_field"}
                                    value={aroma.remainsVerificator ? aroma.remainsVerificator : ""}
                                    onChange={e => (setAroma({...aroma, remainsVerificator: e.target.value}),setChangeRemainField(true))}
                                    type="text"  />
                            </div>
                            <div className={"conteiner_for_save_remain"}>
                                    <span onClick={e => saveRemainField()} className={changeRemainField ? "save_remain_button active" : "save_remain_button"}>
                                        Cохранить
                                    </span>
                            </div>
                        </div>

                    }
                    {
                        setsForAroma ? setsForAroma.map((item, index) =>
                            <Link to={`/adm/sets/${item.id}`} key={index} className={item.wrong ? "xcvtrjn red" : "xcvtrjn"}>
                                {item.name}
                            </Link>
                        ) : ''

                    }

                    {
                        user.getUserRights.view_all_price &&
                        <div className={"table_math_prices"}>

                            <div className={"wpap_btn_table"}>
                                <div className={"wrap_fspt"}>
                                    {/*<span className={"juater"}>Только ручное изменение</span>*/}
                                    {/*<div onClick={e => changeHandlTableSelector(!aroma.handChangeTable)} className={aroma && aroma.handChangeTable ? "box_checkbox_z active" : "box_checkbox_z"}></div>*/}
                                    <div className={"selector_container"}>
                                        <span onClick={e => setOpenMenuSelector(!openMenuSelector)} className={"current_item_selected"}>{selectedStopper ? selectedStopper : "Выключено"}</span>
                                        <div className={ openMenuSelector ? "other_items_for_select active" : "other_items_for_select"}>
                                            {
                                                mlForSelected ? mlForSelected.map((item, index) =>
                                                    <div onClick={e => selectedStopperHandler(item.value)} className={"one_item_for_select"}>
                                                        {item.value ? item.value : "Выключить" }
                                                    </div>
                                                ) : ''
                                            }
                                        </div>
                                    </div>
                                    <span onClick={e => saveTableOptionsHandler()} className={"save_options_table"}>Сохранить настройки таблицы</span>
                                    <div onClick={e => openEditAndSaveHandler()} className={"save_options_table const_size"}>{messageEditButton ? messageEditButton : ""}</div>
                                    <div className={openEditMenu ? "wrapper_edit_menu open" : "wrapper_edit_menu"}>
                                        {
                                            selectedItems ? selectedItems.map((item, index) =>
                                                <div className={"alt_container"}>
                                                    <span>{item.value}</span>
                                                    <div onClick={e => switchEditHandler(index)} className={item.check ? "box_checkbox_alt active" : "box_checkbox_alt"}>

                                                    </div>
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                    <span className={"success_message_span"}> {successMessage ? successMessage : ""} </span>
                                </div>


                            </div>

                            <div className={"top_line_sprav strong"}>
                                <div className={"kktfp volume_ml"}>вкл / выкл</div>
                                <div className={"kktfp volume_ml"}>Объем,мл</div>
                                <div className={"kktfp sebes"}>Себестоимость, ₽</div>
                                <div className={"kktfp opt_ru"}>Оптовая, ₽</div>
                                <div className={"kktfp opt_s"}>Оптовая, $</div>
                                <div className={"kktfp price_site"}>Цена на сайте, ₽</div>
                                <div className={"kktfp art"}>Артикул</div>
                            </div>
                            {
                                childProducts ? childProducts.map((item, index) =>
                                    <div className={"top_line_sprav"}>
                                        <div className={"kktfp volume_ml"}>
                                            <div onClick={e => switchCheckboxHandler(index)} className={item.items_ml_table.isActive ? "box_checkbox_z active" : "box_checkbox_z"}>

                                            </div>
                                        </div>
                                        <div className={"kktfp volume_ml"}>{item.value}</div>
                                        <div className={"kktfp sebes"}>{(item.items_ml_table.sebestoimost_r*settings.getRubCurse).toFixed()}</div>
                                        <div className={"kktfp opt_ru"}>{(item.items_ml_table.opt_price_r*settings.getRubCurse).toFixed()}</div>
                                        <div className={"kktfp opt_s"}>{item.items_ml_table.opt_price_s}</div>
                                        <div className={"kktfp price_site"}>{(item.items_ml_table.price_for_site*settings.getRubCurse).toFixed()}</div>
                                        <input onChange={e => switchArticulHandler(index,e.target.value)} value={item.items_ml_table.articul} className={ user.getUserRights.set_articul_aroma ? "kktfp art" : "kktfp art pnt_none"}/>
                                    </div>
                                ) : ''
                            }

                        </div>
                    }


                </div>
            </div>

        </div>
    );
});

export default DetailAreaAroma;
