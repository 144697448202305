import React, {useCallback, useContext, useEffect, useState} from 'react';
import './styles/App.css';
import Header from "./components/header/header";
import Footer from "./components/footer/Footer";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {check} from "./http/userAPI";
import {useLocation, useNavigate} from "react-router";
import {fetchCountsForIndicators, getSettings} from "./http/AromaApi";
import {set} from "mobx";
import FileManager from "./pages/FileManager/FileManager";




const App = observer(() => {

    const {user,settings} = useContext(Context)
    const [loading, setLoading] = useState(true);

    useEffect(() => {

       check().then(data => {
           if(!data) {
               user.setIsAuth(false)
           } else {
               user.setUser(data);
               // console.log(data)
               user.setIsAuth(true);
               // console.log(data);
               if(data.rights) {
                   user.setUserRights(JSON.parse(data.rights));
                   // console.log(JSON.parse(data.rights))
                   console.log(user.getUserRights)
               }
               getSettings().then(data => {
                   if(data) {
                       settings.setRubCurse(data.data.rubCurse);
                   }
               })

               fetchCountsForIndicators().then(data => {
                   console.log(data);
                   console.log("INDICATORS")
                   settings.setNoArt(data.noArt)
                   settings.setNoLink(data.noLink)
                   settings.setWrongItems(data.wrongItems)
               })
               // window.location.replace('/user');
           }
        })


    },[])



        useEffect(() => {
            document.title = 'Прайс-каталог';
        }, []);




  return (
    <div className="App">
        {
                <BrowserRouter>
                    <Header user={user.user}/>

                    <div className={"all_wrapper_body"}   style={
                        {minHeight:settings.bodySize, height:"100%"}
                    }>
                        <AppRouter/>
                        <FileManager/>
                    </div>
                    <Footer/>
                </BrowserRouter>

        }


    </div>
  );
})

export default App;
