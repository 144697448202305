import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {useLocation} from "react-router";
import {Link, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {
    changeActiveAroma,
    changeAromaName, changeButtonStatus,
    getAromaAndEntrance,
    getHistoryAromaById,
    updateBrandByAromaId
} from "../../http/AromaApi";
import "./EntranceByPost.css";
import {EntranseByPost, fetchAllPost} from "../../http/PostavAPI";
import {getHumanDate} from "../../http/SupportFunctions";
import Pagination from "../../components/UI/Paginstion";


const EntranceByPost = observer(() => {


    const {settings,user} = useContext(Context);
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const [refresh, setRefresh] = useState(false);

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)



    let page = +queryParams.get('page');

    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }
    let id = params.id;
    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const [entrances, setEntrances] = useState(null);
    const [postav, setPostav] = useState(null);

    useEffect(() => {

        EntranseByPost(limit,(page-1)*limit,id).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }

            console.log(response)
            setCount(response.count)
            setEntrances(response.items)
            setPostav(response.postav);
        })
    },[page])


    // target="_blank"
        return (
            <div className={"detail_entrance_by_post"}>
                <span className={"ent_title"}>Все поставки с поставщиком {postav ? postav.name : ""}</span>
                <div className={"post_wrapper_by"}>
                    <div className={"wrapper_line_by"}>
                        <div className={"byentrace big-b"}>Название</div>
                        <div className={"byentrace small-b"}>Обьем</div>
                        <div className={"byentrace center-b"}>Количество</div>
                        <div className={"byentrace center-b"}>Цена за шт ₽</div>
                        <div className={"byentrace big-b"}>Дата</div>
                        <div className={"byentrace big-b"}>Поставщик</div>
                    </div>
                    {
                        entrances ? entrances.map((item, index) =>
                            <div className={"wrapper_line_by"}>
                                <Link to={`/adm/aroma/${item.itemId}`} target="_blank" className={"byentrace big-b"}>{item.name_item}</Link>
                                <div className={"byentrace small-b"}>{item.volume}</div>
                                <div className={"byentrace center-b"}>{item.cout}</div>
                                <div className={"byentrace center-b"}>{ (item.ml_price_S*settings.getRubCurse).toFixed(2)+""}</div>
                                <div className={"byentrace big-b"}>{getHumanDate(item.createdAt)}</div>
                                <div className={"byentrace big-b"}>{postav ? postav.name : ""}</div>
                            </div>
                        ) : ''
                    }
                    <Pagination page={page} limit={limit} count={count}/>
                </div>
            </div>
        )


});

export default EntranceByPost;
