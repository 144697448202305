import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import "./fileManager.css";
import {addFile, addImages, createFolder, deleteFolderOrFile, readDir, renameFolderAndFile} from "../../http/AromaApi";
import {Link} from "react-router-dom";
import {values} from "mobx";
import {getHumanDate} from "../../http/SupportFunctions";

const FileManager = observer(() => {
    const {user,settings} = useContext(Context)
    const [refresh,setRefresh] = useState(false);
    const [startPath, setStartPath] = useState(null);
    const [workPath, setWorkPath] = useState(null);

    const [files,setFiles] = useState(null);
    const [folders,setFolders] = useState(null);



    const [selectedFolderOrFile, setSelectedFolderOrFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const closeManager = () => {
        settings.setOpenManager(false);
    }
    const selectedFolderHandler = (name) => {
            if(selectedFolderOrFile != name) {
                setSelectedFolderOrFile(name)
                setNewNameTemp(name);
                setFieldForNewName(false)
            } else {
                let newPath = workPath+'\/'+name;
                setWorkPath(newPath)
            }

        setSelectedFile(null)

    }
    const selectedNameHandler = (name) => {
        if(selectedFile != name) {
            setSelectedFile(name)
            setSelectedFolderOrFile(null)
            setNewNameTemp(name)
            setFieldForNewName(false)
        }

    }

    const backDirectoryHandler = () => {
        var startIndex = workPath.lastIndexOf("/"); //Here we are getting the last index of _ char
        let string = workPath;

        var result = string.slice(0, startIndex); //as we need only `a` we are using `startIndex + 1`
        setSelectedFile(null)
        setWorkPath(result);
        setSelectedFolderOrFile(null)
    }

    useEffect(() => {

        readDir(workPath).then(response => {
            console.log(response)
            if(!startPath) {
                setStartPath(response.currentPath);
            }


            if(workPath !== response.currentPath) {
                setWorkPath(response.currentPath)
            }
            if(response.files) {
                setFiles(response.files)
            } else {
                setFiles(null)
            }

            if(response.folders) {
                setFolders(response.folders)
            } else {
                setFolders(null)
            }

            console.log("MANAGER FILES====================")
        })

    },[workPath,refresh])

    const [openCreateFolder, setOpenCreateFolder] = useState(false);
    const [newFolderName,setNewFolderName] = useState("");
    const [errorCreateFolder, setErrorCreateFolder] = useState("")
    const openCreateFolderModalHandler = () => {
        setOpenCreateFolder(!openCreateFolder);
        user.setShadowOverlay(!user.shadowOverlay)
    }

    const createFolderHandler =  async  () => {
        let response = await createFolder(workPath,newFolderName);
        console.log(response)
        if(response.message) {
            setOpenCreateFolder(false);
            setNewFolderName("");
            user.setShadowOverlay(false)
            setRefresh(!refresh)
        }
        if (response.error) {
            setErrorCreateFolder(response.error)
        }
    }


    useEffect(() => {
        if(user.shadowOverlay == false) {
            setOpenCreateFolder(false);
            setOpenDelete(false);
        }
    },[user.shadowOverlay])

    const [openDelete, setOpenDelete] = useState(false);


    const openDeleteHandler = () => {
        setOpenDelete(!openDelete);
        user.setShadowOverlay(!user.shadowOverlay)
    }

    const deleteFolderOrFileHandler = async () => {
        let type = "";
        let dir = workPath;
        let selected = ""
        if(selectedFolderOrFile) {
            type = "folder";
            selected = selectedFolderOrFile;
        }
        if (selectedFile) {
            type = "file";
            selected = selectedFile;
        }


        let response = await deleteFolderOrFile(dir,selected,type);
        if(response.message) {
            setOpenDelete(false);
            user.setShadowOverlay(false)
            setSelectedFile(null)
            setSelectedFolderOrFile(null)
            setNewNameTemp("");
            setFieldForNewName(false)
            setRefresh(!refresh)


        }
    }


    const uploadFile = (e,fieldName, index) => {

        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("file", newFile);

        addFile(formData,workPath).then(response => {
            if(response.message) {
                setRefresh(!refresh)
            }
        })

    }


    const getPathSupport = (string,value) => {

        let link = "/home/alure-parfum-app/server/static";
        if (string == link) {
            return "/"+value;
        }
        let newStr = string.replace(link,"");  //
        newStr = newStr+"/"+value;
        return newStr;
    }


    ////rename////

    const [newNameTemp,setNewNameTemp] = useState("");
    const [fieldForNewName,setFieldForNewName] = useState(false);


    const supportRenameHandler = () => {
        setFieldForNewName(true)
    }

    const saveNewNameHandler = async () => {
        let oldName = "";
        if(selectedFile) {
            console.log(selectedFile)
            oldName = selectedFile;
        }
        if(selectedFolderOrFile) {
            console.log(selectedFolderOrFile)
            oldName = selectedFolderOrFile
        }

        let response = await renameFolderAndFile(workPath,oldName,newNameTemp);
        console.log(response)
        if(response.message) {
            setNewNameTemp("");
            setFieldForNewName(false)
            setSelectedFolderOrFile("")
            setSelectedFile("")
            setRefresh(!refresh)
        }

    }



    return (
        <div className={ settings && settings.openManager ? "file_manager_wrapper active" : "file_manager_wrapper"}>
            <div className={"outw_area"}>


                <div className={ openCreateFolder ? "create_new_folder active" : "create_new_folder"}>
                    <span className={"create_folder_name"}>Введите название новой папки</span>
                    <input className={"input_for_name_folder"} value={newFolderName ? newFolderName : ""} onChange={e => setNewFolderName(e.target.value)} type="text"/>
                    <span className={"error_text_message_create"}>{errorCreateFolder ? errorCreateFolder : ""}</span>
                    <div className={"button_case"}>
                        <span  onClick={e => createFolderHandler()} className={"manager_button_setap"}>Создать папку</span>
                        <span onClick={e => openCreateFolderModalHandler()} className={"manager_button_setap"}>Отмена</span>
                    </div>

                </div>

                <div className={ openDelete ? "create_new_folder active" : "create_new_folder"}>
                    <span className={"create_folder_name"}>Подтвердите удаление</span>
                    <div className={"question_delete"}>
                        Удалить { selectedFile ? selectedFile : selectedFolderOrFile} ?
                    </div>
                    <div className={"button_case"}>
                        <span  onClick={e => deleteFolderOrFileHandler()} className={"manager_button_setap"}>Удалить</span>
                        <span onClick={e => openDeleteHandler()} className={"manager_button_setap"}>Отмена</span>
                    </div>

                </div>


                <div className={"first_panel_buttons"}>
                    <div className={"manager_button_setap"}>
                        <div className={"file_upload_btn"}>
                            <input  onChange={ e => uploadFile(e, 'imgUpper')} className={""} type="file" />
                            <span className={"abs_text_upload"}>Загрузить файл</span>
                        </div>
                    </div>
                    <div onClick={e => openCreateFolderModalHandler()} className={"manager_button_setap"}>
                        Создать папку
                    </div>
                    {
                        user.user.role == "admin" && (selectedFile || selectedFolderOrFile) ?
                            <div onClick={e => openDeleteHandler()}  className={"manager_button_setap"}>
                                Удалить
                            </div>
                            : ""
                    }

                    {
                        (selectedFile || selectedFolderOrFile) ?
                        <div onClick={e => supportRenameHandler()}  className={"manager_button_setap"}>
                            Переименовать
                        </div>
                        : ""
                    }
                    {
                        fieldForNewName &&
                        <div className={"rename_wrapper"}>
                            <input className={"rename_input"} value={newNameTemp} onChange={e => setNewNameTemp(e.target.value)} type="text"/>
                            <div onClick={e => saveNewNameHandler()}  className={"manager_button_setap"}>
                                Сохранить
                            </div>
                        </div>
                    }

                    <div onClick={e => closeManager()} className={"close_file_manager"}>

                    </div>
                </div>
                <div className={"path_line"}>
                    {workPath ? workPath : ""}
                </div>
                <div className={"top_line_headr"}>
                    <div className={"icon_container_line strong align-center"}>

                    </div>
                    <div className={"name_container_line strong align-center"}>Название</div>
                    <div className={"massa strong align-center"}>
                        Размер файла
                    </div>
                    <span className={"download_link strong align-center"} >Ссылка на скачивание</span>
                    <div className={"date_manager strong align-center"}>Дата изменения</div>
                </div>
                <div className={"items_list_manager"}>
                    {
                        startPath && workPath && startPath.length < workPath.length ?
                            <div className={"back_line_manager"} onClick={e => backDirectoryHandler()}>
                                <div className={"icon_container_line"}>
                                    <img className={"mini_back_arrow"} src="/back.png" alt=""/>
                                </div>
                                <div className={"name_container_line"}>Назад</div>
                            </div> :  <div className={"back_line_manager no_hover"}> </div>
                    }
                    {
                        folders ? folders.map((item, index) =>
                            <div className={selectedFolderOrFile == item.name ? "line_manager selected" : "line_manager"} onClick={e => selectedFolderHandler(item.name)} key={index}>

                               <div className={"icon_container_line"}>
                                   <img src="/folder_item.png" alt=""/>
                               </div>
                                <div className={"name_container_line"}>{item.name}</div>
                                <div className={"date_manager"}>{getHumanDate(item.date)}</div>
                            </div>

                        ) : ''
                    }
                    {
                        files ? files.map((item, index) =>
                            <div className={selectedFile == item.name ? "line_manager selected" : "line_manager"} onClick={e => selectedNameHandler(item.name)} key={index}>

                                <div className={"icon_container_line"}>
                                    <img src="/image_file.png" alt=""/>
                                </div>
                                <div className={"name_container_line"}>{item.name}</div>
                                <div className={"massa"}>
                                    {(item.size).toFixed(2)} КБ
                                </div>
                                <a className={"download_link"} href={getPathSupport(workPath,item.name) } download target="_blank" >Download</a>
                                <div className={"date_manager"}>{getHumanDate(item.date)}</div>
                            </div>

                        ) : ''
                    }
                </div>
            </div>
        </div>
    );
});

export default FileManager;

